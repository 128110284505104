function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import internalAnalyticsService from 'services/InternalAnalyticsService';
import ProductUtils from './ProductUtils';
import AuthUtils from './AuthUtils';
/**
 * Aciona a tag que acompanha quaisquers eventos emitidos com o nome 'assertivaTrackingTrigger'
 *
 */
const sendAnalytics = function (event) {
  let googleAnalytics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let internalAnalytics = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const customEventName = 'assertivaTrackingTrigger';
  const productId = ProductUtils.getProductIdByProductNameEnv();
  let parsedEvent = {
    eventCategory: event === null || event === void 0 ? void 0 : event.eventCategory,
    eventAction: event === null || event === void 0 ? void 0 : event.eventAction,
    eventLabel: event === null || event === void 0 ? void 0 : event.eventLabel
  };
  if (AuthUtils.getUser().isAssertiva()) {
    parsedEvent = _objectSpread(_objectSpread({}, parsedEvent), {}, {
      eventValue: 1
    });
  }
  if (googleAnalytics) {
    try {
      var _window;
      const eventToEmit = _objectSpread({
        hitType: 'event',
        event: customEventName
      }, parsedEvent);
      (_window = window) === null || _window === void 0 || (_window = _window.dataLayer) === null || _window === void 0 || _window.push(eventToEmit);
    } catch (error) {
      console.error(error);
    }
  }
  if (internalAnalytics) {
    try {
      internalAnalyticsService.postInternalMetric(_objectSpread({
        productId
      }, parsedEvent));
    } catch (error) {
      console.error(error);
    }
  }
};
const AnalyticsUtils = {
  sendAnalytics
};
export default AnalyticsUtils;