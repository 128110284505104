function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useCallback, useRef, useState } from 'react';
import NotificationService from 'services/NotificationService';
const initialState = {
  notifications: {
    counter: 0,
    list: [],
    error: false,
    loading: false
  },
  pagination: {
    page: 0,
    totalPages: 0
  },
  open: false,
  selectedTab: 0
};
const useNotificationsMenu = () => {
  const [state, setState] = useState(_objectSpread({}, initialState));
  const anchorRef = useRef(null);
  const handleState = useCallback(changes => setState(state => {
    return _objectSpread(_objectSpread({}, state), changes);
  }), []);
  const handlePagination = useCallback(changes => setState(state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      pagination: _objectSpread(_objectSpread({}, state.pagination), changes)
    });
  }), []);
  const handleNotifications = useCallback(changes => setState(state => {
    return _objectSpread(_objectSpread({}, state), {}, {
      notifications: _objectSpread(_objectSpread({}, state.notifications), changes)
    });
  }), []);
  const handleToggle = () => {
    const isOpen = state.open;
    if (isOpen) {
      handleClose();
      return;
    }
    handleState({
      open: true
    });
    fetchNotifications().then(list => list && markNotificationsAsRead(list));
  };
  const markSingleNotificationAsRead = notification => {
    if (notification.read) return;
    NotificationService.patchNotificationsById([{
      id: notification.id,
      read: true
    }]).then(() => {
      fetchNotificationsCounter();
      const newNotification = _objectSpread(_objectSpread({}, notification), {}, {
        read: true
      });
      handleNotifications({
        list: state.notifications.list.map(item => item.id === notification.id ? _objectSpread({}, newNotification) : item)
      });
    });
  };
  const markNotificationsAsRead = notifications => {
    try {
      const dataParam = notifications.map(notification => ({
        id: notification.id,
        read: true
      }));
      NotificationService.patchNotificationsById(dataParam).then(() => fetchNotificationsCounter());
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => {
    if (!state.open) return;
    handleState({
      open: false
    });
  };
  const handleChangeTab = (event, newValue) => handleState({
    selectedTab: newValue
  });
  const fetchNotificationsCounter = async () => {
    try {
      const response = await NotificationService.getNotificationsCounter();
      const {
        count
      } = response.data;
      handleNotifications({
        counter: count
      });
    } catch (error) {
      console.error(error);
    }
  };
  const fetchNotifications = async function () {
    let page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    try {
      handleNotifications({
        loading: true
      });
      const response = await NotificationService.getAllNotifications(page);
      const {
        list,
        numPage,
        totalPages
      } = response.data;
      handlePagination({
        page: numPage,
        totalPages
      });
      if (page > 0) {
        handleNotifications({
          list: [...state.notifications.list, ...list]
        });
        return list;
      }
      handleNotifications({
        list
      });
      return list;
    } catch (error) {
      handleNotifications({
        error: true
      });
      console.error(error);
    } finally {
      handleNotifications({
        loading: false
      });
    }
  };
  const reachedLastNotificationsPage = () => {
    const {
      pagination: {
        page,
        totalPages
      }
    } = state;
    const nextPage = page + 1;
    return nextPage > totalPages - 1;
  };
  const requestMoreNotifications = () => {
    if (reachedLastNotificationsPage()) return;
    const {
      pagination: {
        page
      }
    } = state;
    fetchNotifications(page + 1).then(list => list && markNotificationsAsRead(list));
  };
  const refetchNotifications = () => {
    fetchNotifications().then(list => {
      if (list) {
        markNotificationsAsRead(list);
        handleNotifications({
          error: false
        });
      }
    });
  };
  const clearNotifications = useCallback(() => {
    const {
      pagination,
      notifications: {
        list,
        error,
        loading
      }
    } = initialState;
    const emptiedState = {
      notifications: {
        // Counter sendo mantido para evitar mostrar um valor que
        // não esteja alinhado com o backend
        counter: state.notifications.counter,
        list,
        error,
        loading
      },
      pagination
    };
    handleState(_objectSpread({}, emptiedState));
  }, [handleState, state.notifications.counter]);
  return {
    open: state.open,
    counter: state.notifications.counter,
    notifications: state.notifications,
    selectedTab: state.selectedTab,
    anchorRef,
    handleToggle,
    handleClose,
    handleChangeTab,
    requestMoreNotifications,
    refetchNotifications,
    reachedLastNotificationsPage,
    markSingleNotificationAsRead,
    clearNotifications,
    fetchNotificationsCounter
  };
};
export default useNotificationsMenu;