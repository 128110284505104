function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useCallback, useState } from 'react';
import { AULoadingManager, AUNotifier } from 'components';
import ProfileService from 'services/ProfileService';
import { AuthUtils, DateUtils, ErrorUtils, FunctionUtils, ValidationUtils } from 'utils';
import history from 'utils/browserHistory';
import MESSAGES from 'containers/EditOwnProfile/constants/MessagesConstants';
import EditProfileUtils from 'containers/EditOwnProfile/utils/EditProfileUtils';
import useFormState from 'hooks/useFormState';
import moment from 'moment';
const useEditProfile = () => {
  const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false);
  const [mfa, setMFA] = useState(null);
  const methods = useFormState(EditProfileUtils.getInitialProfileFormState());
  const [profileErrors, setProfileErrors] = useState(EditProfileUtils.getInitialProfileErrors());
  const togglePasswordChangeModal = () => {
    setOpenPasswordChangeModal(prev => !prev);
  };
  const {
    form,
    setValues,
    resetValues
  } = methods;
  const checkAllErrors = useCallback(() => {
    const actualErrors = EditProfileUtils.getInitialProfileErrors();
    if (!form.name) {
      actualErrors.hasError = true;
      actualErrors.name.message = MESSAGES.NAME_IS_REQUIRED;
    }
    const momentDate = DateUtils.getAsMoment(form.birthdate);
    if (form.birthdate && !(momentDate !== null && momentDate !== void 0 && momentDate.isValid())) {
      actualErrors.hasError = true;
      actualErrors.birthdate.message = MESSAGES.BIRTHDATE_IS_INVALID;
    }
    if (momentDate !== null && momentDate !== void 0 && momentDate.isValid()) {
      const maxPastDate = moment().locale('pt-br').subtract(100, 'years');
      const minPastDate = moment().locale('pt-br').subtract(10, 'years');
      const isVeryOld = momentDate.isBefore(maxPastDate);
      const isVeryYoung = momentDate.isAfter(minPastDate);
      if (isVeryOld || isVeryYoung) {
        actualErrors.hasError = true;
        actualErrors.birthdate.message = MESSAGES.BIRTHDATE_NOT_ALLOWED;
      }
    }
    if (AuthUtils.getUser().getCompany().mfa === false) {
      if (form.mobile && !ValidationUtils.isValidPhone(form.mobile, 'mobile')) {
        actualErrors.hasError = true;
        actualErrors.mobile.message = MESSAGES.MOBILE_IS_INVALID;
      }
    }
    if (form.phone && !ValidationUtils.isValidPhone(form.phone, 'landline')) {
      actualErrors.hasError = true;
      actualErrors.phone.message = MESSAGES.PHONE_IS_INVALID;
    }
    setProfileErrors({
      hasError: actualErrors.hasError,
      name: actualErrors.name,
      phone: actualErrors.phone,
      mobile: actualErrors.mobile,
      birthdate: actualErrors.birthdate
    });
    return actualErrors.hasError;
  }, [form.mobile, form.email, form.phone, form.name, form.birthdate]);
  const resetErrors = () => {
    setProfileErrors(EditProfileUtils.getInitialProfileErrors());
  };
  const clearErrors = name => {
    setProfileErrors(prev => _objectSpread(_objectSpread({}, prev), {}, {
      [name]: {
        message: ''
      }
    }));
  };
  const onLogout = data => {
    AuthUtils.logout().then(() => {
      history.push('/');
      AUNotifier.success(data === null || data === void 0 ? void 0 : data.message);
    });
  };
  const sendNewPassword = useCallback(_ref => {
    let {
      oldPassword = '',
      newPassword = '',
      emailShared
    } = _ref;
    const ASSERTIVA = '~ASSERTIVA~';
    const {
      email
    } = form;
    AULoadingManager.show();
    ProfileService.sendNewPassword({
      oldPassword: btoa(FunctionUtils.joinParamsWith(email || emailShared, oldPassword)(ASSERTIVA)),
      newPassword: btoa(FunctionUtils.joinParamsWith(newPassword, newPassword)(ASSERTIVA))
    }).then(_ref2 => {
      let {
        data
      } = _ref2;
      if (data !== null && data !== void 0 && data.mfa) {
        setMFA(data);
      } else {
        if (openPasswordChangeModal) {
          togglePasswordChangeModal();
        }
        onLogout(data);
      }
    }).catch(error => AUNotifier.error(ErrorUtils.normalizeErrorMessage(error))).finally(() => AULoadingManager.close());
  }, [form.email, openPasswordChangeModal]);
  const updateOwnProfile = useCallback(callback => {
    if (checkAllErrors()) return;
    const payload = EditProfileUtils.prepapeEditProfilePayload(form);
    AULoadingManager.show();
    ProfileService.updateOwnProfile(payload).then(response => {
      AUNotifier.success(response.data.message);
      callback(response);
    }).catch(error => {
      AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
    }).finally(() => AULoadingManager.close());
  }, [checkAllErrors, form.birthdate, form.name, form.mobile, form.phone]);
  const getOwnProfile = useCallback(() => {
    AULoadingManager.show();
    ProfileService.getOwnProfile().then(response => {
      setValues(response.data);
    }).catch(() => {
      resetValues();
    }).finally(() => {
      AULoadingManager.close();
    });
  }, []);
  return _objectSpread(_objectSpread({}, methods), {}, {
    openPasswordChangeModal,
    togglePasswordChangeModal,
    sendNewPassword,
    updateOwnProfile,
    getOwnProfile,
    checkAllErrors,
    profileErrors,
    resetErrors,
    clearErrors,
    onLogout,
    mfa
  });
};
export default useEditProfile;