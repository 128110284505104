function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useState, useRef } from 'react';
import DeviceDetector from 'device-detector-js';
import Notifier from 'components/Notification/Notifier';
import LoadingManager from 'components/Loading/LoadingManager';
import ErrorUtils from 'utils/ErrorUtils';
import ValidationUtils from 'utils/ValidationUtils';
import useRulesValidation from 'hooks/useRulesValidation';
import OAuth2Service from 'services/OAuth2Service';
const usePasswordRecovery = _ref => {
  let {
    onlyCpf,
    onSuccess,
    onError
  } = _ref;
  const detector = new DeviceDetector();
  const recaptchaRef = useRef(null);
  const [state, setState] = useState({
    document: '',
    email: ''
  });
  const device = detector.parse(navigator.userAgent);
  const deviceInfo = _objectSpread(_objectSpread({}, device), {}, {
    device: _objectSpread(_objectSpread({}, device.device), {}, {
      resolution: "".concat(window.screen.width, "x").concat(window.screen.height)
    })
  });
  const {
    errors,
    validate,
    clearError
  } = useRulesValidation([{
    field: 'document',
    messageError: "".concat(onlyCpf ? 'CPF' : 'Documento', " \xE9 obrigat\xF3rio."),
    hasErrors: () => !state.document
  }, {
    field: 'document',
    messageError: "Documento inv\xE1lido.",
    hasErrors: () => !ValidationUtils.isValidCnpj(state.document) && !ValidationUtils.isValidCpf(state.document)
  }, {
    field: 'email',
    messageError: 'E-mail é obrigatório.',
    hasErrors: () => !state.email
  }, {
    field: 'email',
    messageError: 'E-mail inválido.',
    hasErrors: () => !ValidationUtils.isValidEmail(state.email)
  }]);
  const handleState = (name, value) => {
    setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      [name]: value
    }));
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (validate() && recaptchaRef) {
      recaptchaRef.current.execute();
    }
  };
  const doSubmit = token => {
    LoadingManager.show();
    OAuth2Service.recoveryPassword({
      document: state.document,
      email: state.email,
      recaptcha: token,
      deviceInfo
    }).then(response => {
      setState({
        document: '',
        email: ''
      });
      onSuccess(response.data.message);
    }).catch(error => {
      const message = ErrorUtils.normalizeErrorMessage(error);
      Notifier.error(message);
      if (onError) onError(message);
    }).finally(() => {
      LoadingManager.close();
    });
  };
  return {
    state,
    errors,
    recaptchaRef,
    handleState,
    clearError,
    handleSubmit,
    doSubmit
  };
};
export default usePasswordRecovery;