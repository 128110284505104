const _excluded = ["timeout", "autoRefreshToken", "onResponseReject", "onResponseResolve", "onRequestResolve", "onRequestReject", "onNotAuthenticate"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
import axios from 'axios';
import _isUndefined from 'lodash/isUndefined';
import messages from 'constants/messages';
import AuthUtils from './AuthUtils';
import ErrorUtils from './ErrorUtils';
import NotificationCookiesUtils from './NotificationCookiesUtils';
import Oauth2CookiesUtils from './Oauth2CookiesUtils';
/**
 * @param timeout timeout das requisições em milissegundos. Default: 60000 (um minuto)
 * @param autoRefreshToken por default o interceptor fará o refresh token automaticamente,
 *  caso não deseje esse comportamento informar esse parametro como autoRefreshToken: false
 * @param onResponseReject callback chamado nos responses das requisições por falha
 * @param onResponseResolve callback chamado nos responses das requisições com sucesso
 * @param onRequestReject callback chamado nos requestes das requisições com falha
 * @param onRequestResolve callback chamado nos requestes das requisições com sucesso
 * @param onNotAuthenticate callback chamado quando o usuário não estiver autenticado
 */
const createAxiosInstance = function () {
  let _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    {
      timeout = 60000,
      autoRefreshToken = true,
      onResponseReject = () => {},
      onResponseResolve = () => {},
      onRequestResolve = () => {},
      onRequestReject = () => {},
      onNotAuthenticate = () => {}
    } = _ref,
    others = _objectWithoutProperties(_ref, _excluded);
  const axiosInstance = axios.create(_objectSpread({
    timeout
  }, others));
  const isTokenExpiredError = error => {
    var _error$response;
    return (error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401;
  };
  const handleNotAuthorized = error => {
    const errorMessage = ErrorUtils.normalizeErrorMessage(error);
    if (!_isUndefined(Oauth2CookiesUtils.getToken())) {
      if ([messages.SHARED_LOGIN, messages.TIME_LIMIT].includes(errorMessage) && errorMessage) {
        NotificationCookiesUtils.setFinisedSessionNotificationCookie(errorMessage);
      } else {
        NotificationCookiesUtils.setFinisedSessionNotificationCookie(messages.INVALID_SESSION);
      }
    }
    Oauth2CookiesUtils.clearCookies();
    onNotAuthenticate(error);
  };
  axiosInstance.interceptors.request.use(async request => {
    try {
      if (!Oauth2CookiesUtils.getToken()) {
        await AuthUtils.refreshToken();
      } else if (autoRefreshToken) {
        await AuthUtils.lazyRefreshToken();
      }
      request.headers.Authorization = Oauth2CookiesUtils.getTokenAsAuthorization();
    } catch (error) {
      if (isTokenExpiredError(error)) {
        handleNotAuthorized(error);
        return Promise.resolve('Ok!');
      } else {
        onRequestReject(error);
      }
      return Promise.reject(error);
    }
    onRequestResolve(request);
    return request;
  }, error => {
    if (isTokenExpiredError(error)) {
      handleNotAuthorized(error);
      return Promise.resolve('Ok!');
    }
    onResponseReject(error);
    return Promise.reject(error);
  });
  axiosInstance.interceptors.response.use(response => {
    onResponseResolve(response);
    return response;
  }, async error => {
    if (isTokenExpiredError(error)) {
      handleNotAuthorized(error);
      return Promise.resolve('Ok!');
    }
    onResponseReject(error);
    return Promise.reject(error);
  });
  return axiosInstance;
};
export default createAxiosInstance;