import { styled } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
export const StyledMenuIcon = styled(IconButton)({
  '& .notifications-menu-icon': {
    color: '#FFF'
  },
  '& .notifications-menu-badge .MuiBadge-badge': {
    color: '#FFF',
    backgroundColor: '#00ADB3',
    height: '20px',
    padding: '0 3px',
    minWidth: '20px',
    marginTop: '4px',
    marginRight: '2px',
    fontSize: '12px'
  }
});