function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import UserGroupModel from 'models/UserGroupModel';
import PubSub from 'pubsub-js';
import GroupService from 'services/GroupService';
import AuthUtils from 'utils/AuthUtils';
import AUNotifier from '../../../components/Notification/Notifier';
import ErrorUtils from '../../../utils/ErrorUtils';
import AULoadingManager from '../../../components/Loading/LoadingManager';
const NOTIFY_USER_GROUP_VIEW = '@assertiva-ui/NOTIFY_USERGROUP_VIEW';
const FlagsPermission = {
  CREATE_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:CRIAR', 'ADMINISTRATIVO:GRUPOS:CRIAR:EMPRESA'],
  UPDATE_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:EDITAR', 'ADMINISTRATIVO:GRUPOS:EDITAR:EMPRESA', 'ADMINISTRATIVO:GRUPOS:EDITAR:GRUPO'],
  VIEW_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:VISUALIZAR', 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:EMPRESA', 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:GRUPO']
};
class UserGroupViewStore {
  constructor() {
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", Object.freeze({
      group: new UserGroupModel()
    }));
    _defineProperty(this, "state", _objectSpread({}, this.initialState));
    _defineProperty(this, "notify", () => {
      PubSub.publish(NOTIFY_USER_GROUP_VIEW, _objectSpread({}, this.state));
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(NOTIFY_USER_GROUP_VIEW, (_, state) => {
        callback(state);
      });
      this.notify();
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "reset", () => {
      this.state = _objectSpread({}, this.initialState);
      this.notify();
    });
    _defineProperty(this, "init", async _ref => {
      let {
        group,
        groupId,
        customerId
      } = _ref;
      AULoadingManager.show();
      this.state.group = group;
      try {
        const groupData = await this.getGroupById({
          customerId,
          groupId
        });
        try {
          if (groupData) {
            this.state.group.users = groupData.users;
            this.state.group.status = this.state.group.setStatus(groupData.status);
            this.state.group.groupName = groupData.name;
            this.state.group.consumptionLimit = groupData.limitationPrice;
            this.state.group.ips = groupData.ips.map(value => ({
              name: value
            }));
            this.state.group.days = groupData.accessLimitation;
          }
          AULoadingManager.close();
          this.notify();
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
      }
    });
    _defineProperty(this, "getSelectData", async _ref2 => {
      let {
        customerId
      } = _ref2;
      try {
        const res = await GroupService.getSelectData({
          customerId
        }).catch(error => {
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
        });
        return res && res.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    });
    _defineProperty(this, "getGroupById", async _ref3 => {
      let {
        customerId,
        groupId
      } = _ref3;
      try {
        const res = await GroupService.getGroupById({
          customerId,
          groupId
        }).catch(error => {
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
        });
        return res && res.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    });
    _defineProperty(this, "getStatusLabel", status => {
      return this.state.group.getStatus(status).toParams.name;
    });
    _defineProperty(this, "hasUpdateGroupByPermission", () => AuthUtils.getUser().hasPermission(FlagsPermission.UPDATE_GROUP));
    _defineProperty(this, "hasViewGroupByPermission", () => AuthUtils.getUser().hasPermission(FlagsPermission.VIEW_GROUP));
  }
}
export default new UserGroupViewStore();