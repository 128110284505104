import { useState } from 'react';
import CONSTANTS from 'containers/constants';
const {
  USER_STATUS,
  MFA_STATUS
} = CONSTANTS;
const useUserRow = () => {
  const [menuAnchor, setMenuAnchor] = useState({
    anchorEl: null,
    position: null
  });
  const openMenuOption = (event, position) => {
    setMenuAnchor({
      anchorEl: event.currentTarget,
      position
    });
  };
  const getColorStatus = statusId => {
    if (!statusId) return '';
    switch (statusId) {
      case USER_STATUS.ACTIVE_ID:
        return 'UserRow-statusChip-active';
      case USER_STATUS.PENDING_ID:
        return 'UserRow-statusChip-pending';
      case USER_STATUS.BLOCK_SECURITY.id:
        return 'UserRow-statusChip-block';
      default:
        return 'UserRow-statusChip-default';
    }
  };
  const getColorMFAStatus = status => {
    if (!status) return '';
    switch (status) {
      case MFA_STATUS.ACTIVE:
        return 'UserRow-statusChip-active';
      case MFA_STATUS.PENDING:
        return 'UserRow-statusChip-pending';
      case MFA_STATUS.SUSPENDED:
        return 'UserRow-statusChip-block';
      default:
        return 'UserRow-statusChip-default';
    }
  };
  const isMenuOptionsOpen = function () {
    let position = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : -1;
    return menuAnchor.position === position;
  };
  const closeMenuOptions = () => setMenuAnchor({
    anchorEl: null,
    position: null
  });
  return {
    getColorStatus,
    getColorMFAStatus,
    menuAnchor,
    openMenuOption,
    isMenuOptionsOpen,
    closeMenuOptions
  };
};
export default useUserRow;