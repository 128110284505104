var _AccessDaysUtils;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import moment from 'moment';
import _zipObject from 'lodash/zipObject';
class AccessDaysUtils {}
_AccessDaysUtils = AccessDaysUtils;
_defineProperty(AccessDaysUtils, "formatTime", (arrayDays, indexDay, IniOrFin) => {
  const accessDayFound = arrayDays.find(val => val.day === indexDay);
  if (accessDayFound) {
    const hourMinute = accessDayFound[IniOrFin].split(':');
    return moment().set({
      hour: hourMinute[0],
      minute: hourMinute[1],
      second: 0,
      millisecond: 0
    }).toISOString();
  }
  return '';
});
_defineProperty(AccessDaysUtils, "setHoursAccessLimitation", function (arrayDays, indexDay, IniOrFin) {
  let key = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  let update = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  if (key) {
    const value = _AccessDaysUtils.formatTime(arrayDays, indexDay, IniOrFin);
    return update(key, value);
  }
  return _AccessDaysUtils.formatTime(arrayDays, indexDay, IniOrFin);
});
_defineProperty(AccessDaysUtils, "generateDays", (days, accessStartTime, accessEndTime, accessStartTimeWeekends, accessEndTimeWeekends) => {
  const hasHour = value => value ? moment(value).seconds(0).format('HH:mm') : '';
  return days.map(val => ['sabado', 'domingo'].includes(val.day) ? _objectSpread({
    day: val.day,
    active: val.active
  }, _zipObject(['startTime', 'endTime'], [hasHour(accessStartTimeWeekends), hasHour(accessEndTimeWeekends)])) : _objectSpread({
    day: val.day,
    active: val.active
  }, _zipObject(['startTime', 'endTime'], [hasHour(accessStartTime), hasHour(accessEndTime)])));
});
export default AccessDaysUtils;