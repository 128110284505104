function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import _toArray from 'lodash/toArray';
import _orderBy from 'lodash/orderBy';
import StringUtils from 'utils/StringUtils';
import AccessDays from 'constants/accessDays';
import history from 'utils/browserHistory';
export const prepareUrl = customerId => "".concat(customerId ? "/".concat(customerId) : '');
export const changedLabelPath = customerId => "".concat(customerId ? 'clientes' : 'grupos-e-usuarios');
export const changedLabelPathSaveForm = customerId => "".concat(customerId ? '/editar' : '');
export const orderByDays = (array, sorting) => {
  return array.slice().sort((a, b) => {
    return sorting.indexOf(a.day) - sorting.indexOf(b.day);
  });
};
export const lastWordString = string => {
  const array = string.split(' ');
  return array[array.length - 1];
};
export const checkGroupAccessDays = array => {
  return {
    weekdays: Boolean(array.find(val => AccessDays.DAYS_WEEK.WEEKDAYS.includes(val.day) && val.active === true)),
    weekends: Boolean(array.find(val => AccessDays.DAYS_WEEK.WEEKENDS.includes(val.day) && val.active === true))
  };
};
export const removeHoursNull = accessLimitation => {
  const hours = accessLimitation.map(value => _objectSpread(_objectSpread({}, value), {}, {
    startTime: !value.startTime ? '' : value.startTime,
    endTime: !value.endTime ? '' : value.endTime
  }));
  return hours;
};
export const controllerToPathUserGroupMenu = _ref => {
  let {
    viewMode,
    customerId,
    groupId
  } = _ref;
  const url = {
    edit: "/".concat(changedLabelPath(customerId)).concat(prepareUrl(customerId), "/grupos/").concat(groupId, "/editar"),
    view: "/".concat(changedLabelPath(customerId)).concat(prepareUrl(customerId), "/grupos/").concat(groupId)
  };
  history.push(viewMode ? url.edit : url.view);
};
export const normalizeAccessLimitation = function () {
  let accessDays = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const orderDays = {
    segunda: 1,
    terca: 2,
    quarta: 3,
    quinta: 4,
    sexta: 5,
    sabado: 6,
    domingo: 7
  };
  let workingDaysLabel = {};
  let daysOffLabel = {};
  const addWordAccent = word => {
    return word.replace('sabado', 'sábado').replace('terca', 'terça');
  };
  const formatTime = function () {
    let time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return "".concat(time).split(':').splice(0, 2).join('h');
  };
  const generateLabels = function (daysArray) {
    let daysOff = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    let daysLabel = {};
    if (daysArray.length >= 1 && daysArray.length < 5) {
      const label = daysArray.length === 2 && daysOff ? 'Sábado e Domingo' : "".concat(_orderBy(daysArray, ['order']).map(day => " ".concat(StringUtils.capitalize(day.weekDay))).toString().trim());
      daysLabel = {
        label,
        timeRange: "das ".concat(daysArray[0].startTime, " \xE0s ").concat(daysArray[0].endTime)
      };
    } else if (daysArray.length === 5) {
      daysLabel = {
        label: "Segunda \xE0 Sexta",
        timeRange: "das ".concat(daysArray[0].startTime, " \xE0s ").concat(daysArray[0].endTime)
      };
    }
    return daysLabel;
  };
  const prepareDays = function (activeDays) {
    let daysOff = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const days = {};
    activeDays.filter(access => !daysOff ? !['sabado', 'domingo'].includes(access.day) : ['sabado', 'domingo'].includes(access.day)).forEach(access => {
      days[access.day] = _objectSpread(_objectSpread({}, access), {}, {
        weekDay: addWordAccent("".concat(access.day)),
        startTime: formatTime(access.startTime),
        endTime: formatTime(access.endTime)
      });
    });
    return days;
  };
  const activeDaysOrdered = accessDays.map(access => _objectSpread(_objectSpread({}, access), {}, {
    order: orderDays[access.day]
  })).filter(access => access.active);
  const workingDays = prepareDays(activeDaysOrdered);
  const daysOff = prepareDays(activeDaysOrdered, true);
  const workDaysArray = _toArray(workingDays);
  const daysOffArray = _toArray(daysOff);
  workingDaysLabel = generateLabels(workDaysArray);
  daysOffLabel = generateLabels(daysOffArray, true);
  return {
    workingDays,
    daysOff,
    workingDaysLabel,
    daysOffLabel
  };
};