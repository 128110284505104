function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import Store from '../UsersTableStore';
import { AuthUtils, FunctionUtils, ProductUtils } from 'utils';
import { FILTERS_KEYS, MENU_OPTIONS, initialFilters } from './constants';
import { EDIT_USERS_COMPANY_LEVEL, EDIT_USERS_GROUP_LEVEL, VIEW_PERMISSIONS, EDIT_CLIENTS_USERS, RESEND_LINK, RESEND_LINK_INTERNAL, DELETE_USER_BACKOFFICE } from 'constants/permissions';
import CONSTANTS from 'containers/constants';
import BASE_PROFILES from 'constants/baseProfiles';
import { DELETE_USER_COMPANY_BACKOFFICE, DELETE_USER_GROUP_BACKOFFICE } from 'constants/permissions';
const {
  USER_STATUS,
  MFA_STATUS
} = CONSTANTS;
const useNewUsersTable = _ref => {
  let {
    customerId,
    groupId,
    pagination,
    viewMode,
    users,
    groups,
    profiles,
    loadUsers,
    loadGroups,
    loadProfiles,
    checkCustomerIdPresence
  } = _ref;
  const [state, setState] = useState(_objectSpread({}, Store.initialState));
  const [filters, setFilters] = useState(initialFilters);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [hasSomeMenuOptionsOnUsers, setHasSomeMenuOptionsOnUsers] = useState(true);
  const location = useLocation();
  const currentUser = AuthUtils.getUser();
  const isBackofficeInterno = ProductUtils.isBackofficeInternoEnv();
  const hasShowMFAQuestionPermission = currentUser.isBackofficeAdminProfile() || currentUser.isSquadVoidProfile() || currentUser.isAtendimentoProfile();
  const handleToggleMFAQuestionDialog = user => Store.toggleMFAQuestionDialog(user);
  const handleMenuOptions = (option, user) => {
    switch (option.key) {
      case MENU_OPTIONS.EDIT_USER:
        Store.toggleEditUserDialog(user);
        break;
      case MENU_OPTIONS.DISABLE_USER:
      case MENU_OPTIONS.ENABLE_USER:
        Store.toggleDisableEnableUserDialog(user);
        break;
      case MENU_OPTIONS.SHOW_USER_PERMISSIONS:
        Store.toggleProfileDialog(user);
        break;
      case MENU_OPTIONS.RESUBMIT:
        Store.resendLinkToNewUser(user);
        break;
      case MENU_OPTIONS.DELETE_USER:
        Store.toggleDeleteUserDialog(user);
        break;
      case MENU_OPTIONS.SHOW_MFA_SECURITY_QUESTION:
        handleToggleMFAQuestionDialog(user);
        break;
      case MENU_OPTIONS.RESET_MFA:
        Store.toggleResetMFADialog(user);
        break;
      default:
        console.error('[UsersTable] opção inválida.');
    }
  };
  const getMenuOptions = user => {
    var _user$mfa, _user$mfa2;
    const {
      status
    } = user;
    const userProfileDestination = user.profile && user.profile.name ? user.profile.name.toLowerCase() : '';
    const hasPermissionHasSameGroupAndCheckProfileDestination = (profile, permission) => {
      var _user$group;
      return currentUser.hasPermission(permission) && currentUser.group.id === (user === null || user === void 0 || (_user$group = user.group) === null || _user$group === void 0 ? void 0 : _user$group.id) && userProfileDestination === profile;
    };
    const hasConditionalPermission = function () {
      let internalPermission = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let externalCompanyPermission = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      let externalGroupPermission = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      if (isBackofficeInterno) {
        return currentUser.hasPermission(internalPermission);
      }
      switch (currentUser.getProfileName().toLowerCase()) {
        case 'administrador':
          /* O administrador pode alterar qualquer usuário da empresa desde que tenha permissão. */
          return currentUser.hasPermission(externalCompanyPermission);
        case 'gerente':
          /* O gerente só pode alterar usuários do perfil operador e caso tenha permissão */
          return hasPermissionHasSameGroupAndCheckProfileDestination('operador', externalGroupPermission);
        default:
          return false;
      }
    };
    const hasEditUsersPermission = () => hasConditionalPermission(EDIT_CLIENTS_USERS, EDIT_USERS_COMPANY_LEVEL, EDIT_USERS_GROUP_LEVEL);
    const activeOrSuspendedMFA = (user === null || user === void 0 || (_user$mfa = user.mfa) === null || _user$mfa === void 0 ? void 0 : _user$mfa.status) === MFA_STATUS.ACTIVE || (user === null || user === void 0 || (_user$mfa2 = user.mfa) === null || _user$mfa2 === void 0 ? void 0 : _user$mfa2.status) === MFA_STATUS.SUSPENDED;
    const hasResetMFAPermission = () => {
      var _user$profile, _user$profile2;
      const isAdminProfile = (user === null || user === void 0 || (_user$profile = user.profile) === null || _user$profile === void 0 ? void 0 : _user$profile.name) === BASE_PROFILES.ADMINISTRATOR.NAME || (user === null || user === void 0 || (_user$profile2 = user.profile) === null || _user$profile2 === void 0 || (_user$profile2 = _user$profile2.base) === null || _user$profile2 === void 0 ? void 0 : _user$profile2.name) === BASE_PROFILES.ADMINISTRATOR.NAME;
      if (isBackofficeInterno) return hasConditionalPermission(EDIT_CLIENTS_USERS) && hasShowMFAQuestionPermission && activeOrSuspendedMFA;
      return !isAdminProfile && activeOrSuspendedMFA && currentUser.isAdminProfile();
    };
    const hasResendLink = () => hasConditionalPermission(RESEND_LINK_INTERNAL, RESEND_LINK, RESEND_LINK);
    const hasRemoveUser = () => {
      if (Number(user.id) === Number(currentUser.id)) {
        return false;
      }
      return hasConditionalPermission(DELETE_USER_BACKOFFICE, DELETE_USER_COMPANY_BACKOFFICE, DELETE_USER_GROUP_BACKOFFICE);
    };
    return [{
      key: MENU_OPTIONS.EDIT_USER,
      title: 'Alterar usuário',
      isEnable: hasEditUsersPermission() && !viewMode && status && [USER_STATUS.INACTIVE_ID, USER_STATUS.ACTIVE_ID].includes(status.id)
    }, {
      key: MENU_OPTIONS.DISABLE_USER,
      title: 'Inativar usuário',
      isEnable: hasEditUsersPermission() && !viewMode && status && status.id === USER_STATUS.ACTIVE_ID
    }, {
      key: MENU_OPTIONS.ENABLE_USER,
      title: 'Ativar usuário',
      isEnable: hasEditUsersPermission() && !viewMode && status && status.id === USER_STATUS.INACTIVE_ID
    }, {
      key: MENU_OPTIONS.SHOW_USER_PERMISSIONS,
      title: 'Visualizar permissões',
      isEnable: AuthUtils.getUser().hasPermission(VIEW_PERMISSIONS)
    }, {
      key: MENU_OPTIONS.RESUBMIT,
      title: 'Reenviar link',
      isEnable: hasResendLink() && status && status.id === USER_STATUS.PENDING_ID
    }, {
      key: MENU_OPTIONS.DELETE_USER,
      title: 'Excluir usuário',
      isEnable: hasRemoveUser() && !viewMode
    }, {
      key: MENU_OPTIONS.RESET_MFA,
      title: 'Recadastrar autenticação por dois fatores',
      isEnable: hasResetMFAPermission()
    }, {
      key: MENU_OPTIONS.SHOW_MFA_SECURITY_QUESTION,
      title: 'Pergunta de segurança',
      isEnable: hasShowMFAQuestionPermission && activeOrSuspendedMFA
    }].filter(option => option.isEnable);
  };
  const applyMenuOptions = users => users.map(user => {
    const menuOptions = getMenuOptions(user);
    if (menuOptions.length > 0 && !hasSomeMenuOptionsOnUsers) {
      setHasSomeMenuOptionsOnUsers(true);
    }
    return _objectSpread(_objectSpread({}, user), {}, {
      menuOptions
    });
  });
  const usersData = Store.getOrderedUsers();
  const paginatedUsers = applyMenuOptions(usersData.users.paginated);
  const allUsers = applyMenuOptions(usersData.users.all);
  const selectedUserIsActive = () => state.selectedUser.status.id === USER_STATUS.ACTIVE_ID;
  const handleResetSelectProfile = () => {
    var _location$state, _location$state2;
    (_location$state = location.state) === null || _location$state === void 0 || delete _location$state.open;
    (_location$state2 = location.state) === null || _location$state2 === void 0 || delete _location$state2.profileId;
    Store.resetSelectedProfile();
  };
  const handleCloseAddNewUserDialog = () => {
    Store.toggleAddNewUserDialog();
    handleResetSelectProfile();
  };

  // Filters logic
  const hasAppliedFilters = Object.values(filters).some(value => !_isEmpty(value));
  const removeEmptyProperties = obj => {
    const newObj = _objectSpread({}, obj);
    Object.keys(newObj).forEach(key => _isEmpty(newObj[key]) && delete newObj[key]);
    return newObj;
  };
  const filtersChips = removeEmptyProperties({
    Nome: filters.name,
    'E-mail': filters.email,
    Status: filters.userStatus,
    Grupo: filters.group,
    Perfil: filters.profile,
    'Autenticação por dois fatores': filters.mfaStatus
  });
  const handleGroupFilter = (appliedGroupFilter, userGroup) => {
    if (!appliedGroupFilter || !userGroup) return true;
    if (appliedGroupFilter !== userGroup) return false;
    return true;
  };
  const filteredUsers = allUsers && allUsers.length > 0 && hasAppliedFilters ? allUsers.filter(user => [filters].some(_ref2 => {
    var _user$mfa3, _user$group2;
    let {
      name,
      email,
      userStatus,
      group,
      profile,
      mfaStatus
    } = _ref2;
    if (name && user.name !== name || email && user.email !== email || userStatus && user.status.name !== userStatus || profile && user.profile.name !== profile || mfaStatus && ((_user$mfa3 = user.mfa) === null || _user$mfa3 === void 0 ? void 0 : _user$mfa3.status) !== mfaStatus || !handleGroupFilter(group, (_user$group2 = user.group) === null || _user$group2 === void 0 ? void 0 : _user$group2.name)) return false;
    return true;
  })) : [];
  const usersToRender = hasAppliedFilters ? FunctionUtils.sliceArrayPerPage(filteredUsers, state.pagination.page, state.pagination.rowsPerPage) : paginatedUsers;
  const toggleFiltersDrawer = () => setOpenFiltersDrawer(!openFiltersDrawer);
  const clearFilters = () => setFilters(initialFilters);
  const removeFilter = filterKey => {
    const newFilters = _objectSpread({}, filters);
    newFilters[FILTERS_KEYS[filterKey]] = '';
    setFilters(newFilters);
  };
  const applyFilters = newFilters => {
    setFilters(_objectSpread(_objectSpread({}, filters), newFilters));
    toggleFiltersDrawer();
  };
  const emptyFiltersResult = paginatedUsers.length > 0 && usersToRender.length === 0;
  useEffect(() => {
    Store.subscribe(setState);
    Store.init({
      customerId,
      groupId,
      users,
      groups,
      profiles,
      viewMode,
      loadUsers,
      loadGroups,
      loadProfiles,
      pagination,
      checkCustomerIdPresence
    });
    return () => {
      Store.reset();
      Store.unsubscribe();
    };
  }, [customerId, groupId, users, groups, profiles, viewMode, pagination, loadUsers, loadGroups, loadProfiles]);
  useEffect(() => {
    var _location$state3, _location$state4;
    if ((_location$state3 = location.state) !== null && _location$state3 !== void 0 && _location$state3.open && (_location$state4 = location.state) !== null && _location$state4 !== void 0 && _location$state4.profileId) {
      var _location$state5;
      Store.handleSelectProfile(Number((_location$state5 = location.state) === null || _location$state5 === void 0 ? void 0 : _location$state5.profileId));
    }
  }, [state.profiles]);
  return {
    state,
    allUsers,
    filteredUsers,
    usersToRender,
    hasSomeMenuOptionsOnUsers,
    handleMenuOptions,
    openFiltersDrawer,
    filtersChips,
    filters,
    toggleFiltersDrawer,
    clearFilters,
    removeFilter,
    applyFilters,
    emptyFiltersResult,
    hasAppliedFilters,
    filterUsersByGroupIfHasGroupId: Store.filterUsersByGroupIfHasGroupId,
    handlePagination: Store.handlePagination,
    toggleEditUserDialog: Store.toggleEditUserDialog,
    saveUser: Store.saveUser,
    reloadUsers: Store.reloadUsers,
    toggleProfileDialog: Store.toggleProfileDialog,
    toggleDeleteUserDialog: Store.toggleDeleteUserDialog,
    toggleDisableEnableUserDialog: Store.toggleDisableEnableUserDialog,
    toggleAddNewUserDialog: Store.toggleAddNewUserDialog,
    handleDisableEnableUserDialog: Store.handleDisableEnableUserDialog,
    submitRemoveUser: Store.submitRemoveUser,
    selectedUserIsActive,
    handleResetSelectProfile,
    handleCloseAddNewUserDialog,
    hasShowMFAQuestionPermission,
    handleToggleMFAQuestionDialog
  };
};
export default useNewUsersTable;