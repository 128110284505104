import { styled } from '@material-ui/core';
import { grey, amber, red } from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
export const StyledUserRowStatusChip = styled(Chip)(_ref => {
  let {
    theme
  } = _ref;
  return {
    '&.UserRow-statusChip-active': {
      backgroundColor: theme.palette.secondary.light
    },
    '&.UserRow-statusChip-pending': {
      backgroundColor: amber[200]
    },
    '&.UserRow-statusChip-block': {
      backgroundColor: red[200]
    },
    '&.UserRow-statusChip-default': {
      backgroundColor: grey[300]
    }
  };
});