var _AuthUtils;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import nanoid from 'nanoid';
import PubSub from 'pubsub-js';
import AdapterUtils from 'adapters/AdapterUtilts';
import LoadingManager from 'components/Loading/LoadingManager';
import Notifier from 'components/Notification/Notifier';
import { Envs } from 'constants/envs';
import MESSAGES from 'constants/messages';
import CONSTANTS from 'constants/notifications';
import { MAX_TIME_TO_REFRESH, MIN_TIME_TO_REFRESH } from 'constants/session';
import { COOKIES_OPTIONS, WEBPHONE_PURPOSE_COOKIE_KEY } from 'constants/utils';
import VENDORS from 'constants/vendors';
import User from 'domains/User';
import OAuth2Service from 'services/OAuth2Service';
import { AuthEvents } from 'types/events';
import { UnitTime } from 'types/session';
import ErrorUtils from 'utils/ErrorUtils';
import Oauth2CookiesUtils from 'utils/Oauth2CookiesUtils';
import CookiesUtil from './CookiesUtil';
import NotificationCookiesUtils from './NotificationCookiesUtils';
import RedirectUtils from './RedirectUtils';
const Window = window;
class AuthUtils {
  constructor() {
    var _this = this;
    _defineProperty(this, "user", new User());
    _defineProperty(this, "isUpdatingSession", false);
    _defineProperty(this, "subscribeEvents", callback => {
      return PubSub.subscribe(CONSTANTS.NOTIFY_AUTH_UTILS_EVENTS, (_, data) => {
        callback(data);
      });
    });
    _defineProperty(this, "unsubscribeEvents", subscription => {
      if (!subscription) return;
      PubSub.unsubscribe(subscription);
    });
    _defineProperty(this, "notifyEvent", event => {
      return PubSub.publish(CONSTANTS.NOTIFY_AUTH_UTILS_EVENTS, event);
    });
    _defineProperty(this, "setUserInfoToWindow", () => {
      if (!Window.dataLayer || !this.user.id) return;
      Window.userInfo = {
        name: this.user.getName(),
        email: this.user.getEmail(),
        profile: this.user.getBaseProfileName(),
        company: this.user.getCompanyName(),
        createdAt: this.user.getCreatedAt(),
        hasAPI: this.user.hasAPI(),
        isCompanyMigration: this.user.isCompanyMigration(),
        isTrial: this.user.isTrial(),
        portal: 'v3'
      };
      Window.assertivaVendors = {};
      AdapterUtils.filterAvailableInfoByAppEnv(VENDORS).forEach(vendor => {
        try {
          Window.assertivaVendors[vendor.key] = vendor.adapter.adapt(this.user);
        } catch (error) {
          console.error(error.message);
        }
      });
      if (Window.assertivaVendors.IntercomUserInfo) {
        const IC = Window.assertivaVendors.IntercomUserInfo;
        Window.dataLayer.push({
          event: 'pushIntercomUserData',
          'Data de criação no portal': IC['Data de criação no portal'],
          Perfil: IC.Perfil,
          Portal: IC.Portal,
          companies: [{
            company_id: IC.companies[0].company_id,
            name: IC.companies[0].name,
            environment: IC.companies[0].environment
          }],
          email: IC.email,
          name: IC.name,
          phone: IC.phone,
          environment: IC.environment,
          user_id: IC.user_id
        });
      }
      const hasUserLoggedIn = Window.dataLayer.find(evt => evt.event === 'user-logged-in');
      if (hasUserLoggedIn) {
        return;
      }
      Window.dataLayer.push({
        event: 'user-logged-in'
      });
    });
    _defineProperty(this, "getToken", () => {
      return Oauth2CookiesUtils.getToken();
    });
    _defineProperty(this, "getSessionInfo", async () => {
      try {
        const response = await OAuth2Service.getSessionInfo();
        const sessionInfo = response.data;
        if (Object.keys(sessionInfo).length) {
          this.user = new User(_objectSpread({}, sessionInfo));
          this.setUserInfoToWindow();
        }
        this.notifyEvent(AuthEvents.UpdateSessionInfo);
        return Promise.resolve(response);
      } catch (error) {
        this.user = new User();
        const errorMessage = ErrorUtils.normalizeErrorMessage(error);
        NotificationCookiesUtils.setFinisedSessionNotificationCookie(errorMessage);
        return Promise.resolve(error);
      }
    });
    _defineProperty(this, "removeUserInfoFromWindow", () => {
      delete Window.userInfo;
      if (Window.assertivaVendors) delete Window.assertivaVendors;
    });
    _defineProperty(this, "updateSession", async () => {
      const updateSessionPromise = async () => {
        this.isUpdatingSession = true;
        await OAuth2Service.getSessionInfo();
        this.notifyEvent(AuthEvents.TokenRefreshed);
        this.isUpdatingSession = false;
      };
      if (!this.isUpdatingSession) {
        await updateSessionPromise();
      }
    });
    _defineProperty(this, "refreshToken", async function () {
      let {
        enableSessionExpiredMessageEnable = true,
        reloadUserInfo = true
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      try {
        const response = await OAuth2Service.refreshToken();
        if (reloadUserInfo) await _this.getSessionInfo();
        _this.notifyEvent(AuthEvents.TokenRefreshed);
        return Promise.resolve(response);
      } catch (error) {
        var _localError$response;
        const localError = error;
        const {
          status
        } = (localError === null || localError === void 0 ? void 0 : localError.response) || {};
        const {
          error_description
        } = (localError === null || localError === void 0 || (_localError$response = localError.response) === null || _localError$response === void 0 ? void 0 : _localError$response.data) || {};
        if (status === 401 && enableSessionExpiredMessageEnable) {
          _this.notifyEvent(AuthEvents.SessionExpired);
        } else if ([500, 504].includes(status)) {
          Notifier.error(ErrorUtils.normalizeErrorMessage(error_description, MESSAGES.TIMEOUT));
        }
        return Promise.reject(error);
      }
    });
    _defineProperty(this, "lazyRefreshToken", () => {
      const timeToFinishSession = Oauth2CookiesUtils.timeToFinishSession(UnitTime.SECONDS);
      if (timeToFinishSession > MAX_TIME_TO_REFRESH && timeToFinishSession <= MIN_TIME_TO_REFRESH) {
        return this.updateSession();
      }
      return Promise.resolve();
    });
    _defineProperty(this, "init", async function () {
      let showLoading = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (_this.getToken()) {
        _this.notifyEvent(AuthEvents.PermissionWaiting);
        if (showLoading) LoadingManager.show({
          mode: 'full'
        });
        await _this.getSessionInfo().then(() => {
          _this.notifyEvent(AuthEvents.PermissionSuccess);
        }).catch(error => {
          const {
            status
          } = error && error.response || {};
          if (status === 403) {
            _this.notifyEvent(AuthEvents.PermissionError);
          } else if (status === 401) {
            _this.notifyEvent(AuthEvents.NotAuthenticated);
          } else {
            _this.notifyEvent(AuthEvents.NotAuthenticated);
          }
        }).finally(() => {
          if (showLoading) LoadingManager.close({
            mode: 'full'
          });
        });
      } else {
        _this.notifyEvent(AuthEvents.NotAuthenticated);
      }
    });
    /**
     * @description por favor utilize o RedirectUtils.createRedirectURL
     */
    _defineProperty(this, "createRedirectURL", (destination, redirectURL) => {
      return RedirectUtils.createRedirectURL(destination, redirectURL);
    });
    /**
     * @description por favor utilize o RedirectUtils.redirectWithRedirectURL
     */
    _defineProperty(this, "redirectWithRedirectURL", function () {
      let destination = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "".concat(Envs().PAINEL_URL, "/login");
      RedirectUtils.redirectWithRedirectURL(destination);
    });
    _defineProperty(this, "redirectWhenNotAuthenticated", authenticated => {
      this.isAuthenticated().then(data => {
        if (!data.isAuthenticated) {
          this.redirectWithRedirectURL();
        } else if (authenticated) {
          authenticated(data);
        }
      });
    });
    _defineProperty(this, "logout", async function () {
      let showNotification = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      let removeMainNotification = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      try {
        LoadingManager.show();
        const data = await _this.isAuthenticated();
        if (data.isAuthenticated) {
          const response = await OAuth2Service.logout();
          Oauth2CookiesUtils.clearCookies();
          if (showNotification) {
            Notifier.info(MESSAGES.SESSION_FINISH, {
              autoHideDuration: 4000
            });
          }
          if (removeMainNotification) {
            _this.notifyEvent(AuthEvents.PostLogout);
          }
          LoadingManager.close();
          return Promise.resolve(response);
        }
      } catch (error) {
        LoadingManager.close();
        return Promise.reject(error);
      }
      _this.notifyEvent(AuthEvents.PostLogout);
      LoadingManager.close();
      return Promise.resolve();
    });
    _defineProperty(this, "login", async _ref => {
      let {
        email,
        password,
        redirectUrl,
        recaptchaToken,
        deviceInfo
      } = _ref;
      try {
        LoadingManager.show();
        const combinedCode = nanoid();
        const codeResponse = await OAuth2Service.getAuthorizationCode({
          email,
          state: combinedCode,
          password,
          redirectUrl,
          recaptchaToken,
          deviceInfo
        });
        const {
          code = undefined,
          state = undefined
        } = codeResponse.data;
        if (combinedCode !== state) {
          throw new Error(MESSAGES.REJECTED_CONNECTION);
        }
        const authenticateResponse = await OAuth2Service.authenticate({
          code,
          redirectUrl,
          deviceInfo
        });
        LoadingManager.close();
        this.notifyEvent(AuthEvents.PostLogin);
        CookiesUtil.clearItem(WEBPHONE_PURPOSE_COOKIE_KEY, COOKIES_OPTIONS);
        return Promise.resolve(authenticateResponse);
      } catch (error) {
        if ((error === null || error === void 0 ? void 0 : error.message) === MESSAGES.REJECTED_CONNECTION) {
          Notifier.error(MESSAGES.REJECTED_CONNECTION);
        } else {
          const message = MESSAGES.BREAK_LINE(ErrorUtils.normalizeErrorMessage(error));
          Notifier.error(message);
        }
        LoadingManager.close();
        return Promise.reject(error);
      }
    });
    _defineProperty(this, "getUser", () => {
      return this.user;
    });
    _defineProperty(this, "isAuthenticated", async function () {
      let {
        enableSessionExpiredMessageEnable = true,
        reloadUserInfo = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const token = _this.getToken();
      const result = Promise.resolve({
        user: _this.getUser(),
        isAuthenticated: false
      });
      if (!token || !_this.getUser().getId()) {
        return result;
      }
      try {
        return {
          user: _this.getUser(),
          isAuthenticated: true
        };
      } catch (_unused) {
        return result;
      }
    });
    if (AuthUtils.instance) {
      return AuthUtils.instance;
    }
    AuthUtils.instance = this;
  }
}
_AuthUtils = AuthUtils;
_defineProperty(AuthUtils, "instance", void 0);
export default new AuthUtils();