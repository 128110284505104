var _NotificationService;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Envs } from 'constants/envs';
import { axiosInstance } from './baseService';
class NotificationService {}
_NotificationService = NotificationService;
_defineProperty(NotificationService, "prepareUrl", () => "".concat(Envs().API_GATEWAY_URL, "/notifications"));
_defineProperty(NotificationService, "getAllNotifications", function () {
  let numPage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return axiosInstance.get("".concat(_NotificationService.prepareUrl(), "/notifications"), {
    params: {
      numPage
    }
  });
});
_defineProperty(NotificationService, "getAllNotificationsExtra", () => {
  return axiosInstance.get("".concat(_NotificationService.prepareUrl(), "/notifications/extra"));
});
_defineProperty(NotificationService, "patchNotificationsById", data => {
  return axiosInstance.patch("".concat(_NotificationService.prepareUrl(), "/notifications"), data);
});
_defineProperty(NotificationService, "getNotificationsCounter", () => {
  return axiosInstance.get("".concat(_NotificationService.prepareUrl(), "/notifications/count"));
});
export default NotificationService;