function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Cookies from 'js-cookie';
import moment from 'moment';
import momenttz from 'moment-timezone';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import { COOKIES_OPTIONS } from 'constants/cookies';
import { getCookieName } from './CookiesUtil';
const ACCESS_TOKEN_MAX_AGE = 'accessTokenMaxAge';
const COUNTDOWN_READ = 'countdownRead';
const LOGIN_TIME = 'loginTime';
const REFRESH_TOKEN = 'refreshToken';
const TOKEN = 'accessToken';
export const countdownReadCookie = getCookieName(COUNTDOWN_READ);
export const dateToFinishSessionCookie = getCookieName(ACCESS_TOKEN_MAX_AGE);
export const loginTimeCookie = getCookieName(LOGIN_TIME);
export const refreshTokenCookie = getCookieName(REFRESH_TOKEN);
export const tokenCookie = getCookieName(TOKEN);
class Oauth2CookiesUtils {
  static clearCookies() {
    Cookies.remove(tokenCookie, COOKIES_OPTIONS);
    Cookies.remove(refreshTokenCookie, COOKIES_OPTIONS);
    Cookies.remove(loginTimeCookie, COOKIES_OPTIONS);
    Cookies.remove(countdownReadCookie, COOKIES_OPTIONS);
  }
  static getToken() {
    return Cookies.get(tokenCookie);
  }
  static getTokenAsAuthorization() {
    return "Bearer ".concat(Oauth2CookiesUtils.getToken() || '');
  }
  static getAccessTokenMaxAgeCookie() {
    return moment(Cookies.get(dateToFinishSessionCookie));
  }
  static getCountdownReadCookie() {
    const countdownRead = Cookies.get(countdownReadCookie);
    if (!_isUndefined(countdownRead)) {
      const read = _get(JSON.parse(Cookies.get(countdownReadCookie)), 'read', false);
      return read;
    }
    return false;
  }
  static setCountdownReadCookie(read) {
    Cookies.set(countdownReadCookie, {
      read
    }, COOKIES_OPTIONS);
  }
  static getLoginTimeCookie() {
    const loginTime = Cookies.get(loginTimeCookie);
    if (!_isUndefined(loginTime)) {
      return JSON.parse(loginTime);
    }
    return {};
  }
  static setLoginTimeCookie() {
    Cookies.set(loginTimeCookie, {
      loginTime: moment(momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSS')).toISOString(),
      read: false
    }, _objectSpread({}, COOKIES_OPTIONS));
  }
  static setLoginTimeCookieAsRead() {
    const currentCookieValue = Oauth2CookiesUtils.getLoginTimeCookie();
    Cookies.set(loginTimeCookie, _objectSpread(_objectSpread({}, currentCookieValue), {}, {
      read: true
    }), _objectSpread({}, COOKIES_OPTIONS));
  }
  static timeToFinishSession(diff) {
    const accessTokenMaxAge = Oauth2CookiesUtils.getAccessTokenMaxAgeCookie();
    if (accessTokenMaxAge.isValid()) {
      return accessTokenMaxAge.diff(moment(momenttz().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss.SSSS')), diff);
    }
    return 0;
  }
}
export default Oauth2CookiesUtils;