function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import browserHistory from 'utils/browserHistory';
export const getQueryParams = options => {
  const searchString = typeof (options === null || options === void 0 ? void 0 : options.searchString) === 'string' ? options === null || options === void 0 ? void 0 : options.searchString : window.location.search;
  let queryParams = qs.parse(searchString, {
    ignoreQueryPrefix: true
  });
  if (_isEmpty(queryParams)) {
    var _queryParams, _options$initialPage, _queryParams2, _options$initialSize;
    queryParams = _objectSpread(_objectSpread({}, queryParams), {}, {
      page: ((_queryParams = queryParams) === null || _queryParams === void 0 ? void 0 : _queryParams.page) || (options === null || options === void 0 || (_options$initialPage = options.initialPage) === null || _options$initialPage === void 0 ? void 0 : _options$initialPage.toString()) || '0',
      size: ((_queryParams2 = queryParams) === null || _queryParams2 === void 0 ? void 0 : _queryParams2.size) || (options === null || options === void 0 || (_options$initialSize = options.initialSize) === null || _options$initialSize === void 0 ? void 0 : _options$initialSize.toString()) || '10'
    });
  }
  return queryParams;
};
export const setQueryParams = (queryParams, options) => {
  var _options$getQueryPara;
  const currentQueryParams = (options === null || options === void 0 || (_options$getQueryPara = options.getQueryParams) === null || _options$getQueryPara === void 0 ? void 0 : _options$getQueryPara.call(options)) || getQueryParams();
  let newQueryParams = _objectSpread(_objectSpread({}, currentQueryParams), queryParams);
  if (options !== null && options !== void 0 && options.forceReset) {
    newQueryParams = _objectSpread(_objectSpread({}, queryParams), {}, {
      page: 0,
      size: currentQueryParams.size
    });
  }
  if (options !== null && options !== void 0 && options.withoutPageAndSize) {
    newQueryParams = queryParams;
  }
  const search = qs.stringify(newQueryParams);
  if (options !== null && options !== void 0 && options.setSearchString) {
    options.setSearchString(search);
    return;
  }
  browserHistory.replace({
    pathname: window.location.pathname,
    search
  });
};
export const getFilters = function () {
  let getQueryParamsFn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getQueryParams;
  const filters = getQueryParamsFn();
  delete filters.page;
  delete filters.size;
  return filters;
};
export const getFiltersKeys = function () {
  let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getFilters();
  return Object.keys(filters);
};
export const getHasFilters = function () {
  let filtersKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getFiltersKeys();
  return filtersKeys.length > 0;
};
export const eraseFilter = (filterKey, options) => {
  var _options$getQueryPara2;
  const queryParams = (options === null || options === void 0 || (_options$getQueryPara2 = options.getQueryParams) === null || _options$getQueryPara2 === void 0 ? void 0 : _options$getQueryPara2.call(options)) || getQueryParams();
  if (!queryParams[filterKey]) {
    throw new Error("Erro ao remover filtro ".concat(filterKey, ". Verifique se essa chave existe na URL (search)."));
  }
  delete queryParams[filterKey];
  const setQueryParamsFn = (options === null || options === void 0 ? void 0 : options.setQueryParams) || setQueryParams;
  setQueryParamsFn(queryParams, {
    forceReset: true
  });
};
export const eraseAllFilters = function () {
  let filtersToPreserve = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let options = arguments.length > 1 ? arguments[1] : undefined;
  const setQueryParamsFn = (options === null || options === void 0 ? void 0 : options.setQueryParams) || setQueryParams;
  setQueryParamsFn(filtersToPreserve, {
    forceReset: true
  });
};