function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import PubSub from 'pubsub-js';
import AUNotifier from 'components/Notification/Notifier';
import UserService from 'services/UserService';
import ErrorUtils from 'utils/ErrorUtils';
import ArrayUtils from 'utils/ArrayUtils';
import LoadingManager from 'components/Loading/LoadingManager';
import CONSTANTS from './constants';
const {
  NOTIFY_STATE_PROFILE_PERMISSION_DIALOG,
  PROFILES_PERMISSIONS_LOADING
} = CONSTANTS;
class ProfilePermissionStore {
  constructor() {
    var _this = this;
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", Object.freeze({
      profile: {},
      productTabIndex: 0,
      modulesTabIndex: 0,
      isCheckedAllPermissionsProduct: {},
      productsPagination: {
        page: 0,
        rowsPerPage: 6
      },
      modulesPagination: {
        page: 0,
        rowsPerPage: 6
      },
      errors: {
        hasErros: false,
        messages: {}
      }
    }));
    _defineProperty(this, "state", _objectSpread({}, this.initialState));
    _defineProperty(this, "customerId", 0);
    _defineProperty(this, "init", _ref => {
      let {
        customerId,
        profileId,
        pagination
      } = _ref;
      if (customerId) {
        this.customerId = customerId;
      }
      if (pagination) {
        this.state.pagination = pagination;
      }
      if (profileId) {
        this.loadPermissions(profileId);
      }
      this.notify();
    });
    _defineProperty(this, "notify", () => {
      PubSub.publish(NOTIFY_STATE_PROFILE_PERMISSION_DIALOG, _objectSpread({}, this.state));
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(NOTIFY_STATE_PROFILE_PERMISSION_DIALOG, (_, state) => {
        callback(state);
      });
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "reset", () => {
      this.state = _objectSpread({}, this.initialState);
      this.notify();
    });
    _defineProperty(this, "handleProductsPagination", (attr, value) => {
      this.state.productsPagination = _objectSpread(_objectSpread({}, this.state.productsPagination), {}, {
        [attr]: value
      });
      this.notify();
    });
    _defineProperty(this, "handleModulesPagination", (attr, value) => {
      this.state.modulesPagination = _objectSpread(_objectSpread({}, this.state.modulesPagination), {}, {
        [attr]: value
      });
      this.notify();
    });
    _defineProperty(this, "toggleCheckAllPermission", productId => {
      if (!this.state.profile.products) return;
      const product = ArrayUtils.findByParams(this.state.profile.products, {
        id: productId
      });
      if (product) {
        const allChecked = this.state.isCheckedAllPermissionsProduct[productId];
        const {
          permissions
        } = product;
        permissions.forEach(permission => {
          this.handlePermission(productId, permission.id, !allChecked, false);
        });
        this.state.isCheckedAllPermissionsProduct[productId] = !allChecked;
        this.notify();
      }
    });
    _defineProperty(this, "getTotalCheckedPermissions", productId => {
      let totalChecked = 0;
      let isAllChecked = false;
      if (!productId || !this.state.profile.products) {
        return {
          totalChecked,
          isAllChecked
        };
      }
      const product = this.state.profile.products.find(p => p.id === productId);
      if (product) {
        totalChecked = product.permissions.filter(permission => permission.active).length;
        if (totalChecked === product.permissions.length) {
          isAllChecked = true;
        }
      }
      return {
        totalChecked,
        isAllChecked
      };
    });
    _defineProperty(this, "handlePermission", function (productId, permissionId, value) {
      let notifyAfterUpdate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      if (_this.state.profile.products) {
        const product = ArrayUtils.findByParams(_this.state.profile.products, {
          id: productId
        });
        if (product && product.permissions.length) {
          const permission = ArrayUtils.findByParams(product.permissions, {
            id: permissionId
          });
          if (permission) {
            permission.active = value;
            try {
              ArrayUtils.replaceByParams(product.permissions, {
                id: permission.id
              }, permission);
            } catch (e) {
              console.error(e);
            }
            if (notifyAfterUpdate) _this.notify();
          }
        }
      }
    });
    _defineProperty(this, "handleProductsTabs", tabIndex => {
      this.handleProductsPagination('page', 0);
      this.state.productTabIndex = tabIndex;
      this.notify();
    });
    _defineProperty(this, "handleModulesTabs", tabIndex => {
      this.handleModulesPagination('page', 0);
      this.state.modulesTabIndex = tabIndex;
      this.notify();
    });
    _defineProperty(this, "loadPermissions", profileId => {
      if (profileId) {
        LoadingManager.show({
          name: PROFILES_PERMISSIONS_LOADING
        });
        UserService.getPermissionsProfile({
          customerId: this.customerId,
          profileId
        }).then(response => {
          this.state.profile = response.data;
        }).catch(error => {
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
        }).finally(() => {
          LoadingManager.close({
            name: PROFILES_PERMISSIONS_LOADING
          });
          this.notify();
        });
      } else {
        this.state.profile = {};
        this.notify();
      }
    });
    _defineProperty(this, "handleProfileName", name => {
      this.state.profile.name = name;
      this.notify();
    });
    _defineProperty(this, "handleProfileDescription", description => {
      this.state.profile.description = description;
      this.notify();
    });
    _defineProperty(this, "saveEditedProfile", profileId => {
      const formattedProducts = this.state.profile.products.map(product => {
        return {
          id: product.id,
          functionalities: product.permissions.map(permission => {
            const {
              functionalityId,
              active
            } = permission;
            return {
              id: functionalityId,
              active
            };
          })
        };
      });
      const profile = {
        name: this.state.profile.name,
        description: this.state.profile.description,
        products: formattedProducts
      };
      LoadingManager.show();
      return UserService.updatedPermissionsProfile(profile, this.customerId, profileId);
    });
  }
}
export default new ProfilePermissionStore();