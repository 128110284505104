function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useState } from 'react';
const useRulesValidation = rules => {
  const [errors, setErrors] = useState({
    hasErrors: false,
    messages: {}
  });
  const validate = function () {
    for (var _len = arguments.length, fields = new Array(_len), _key = 0; _key < _len; _key++) {
      fields[_key] = arguments[_key];
    }
    const formErrors = _objectSpread({}, errors);
    const applyValidation = fieldName => {
      return fields.length === 0 || fields.includes(fieldName);
    };
    const isDisable = rule => {
      return rule.disabled !== undefined ? rule.disabled : false;
    };
    rules.forEach(rule => {
      if (applyValidation(rule.field) && !isDisable(rule) && rule.hasErrors() && !formErrors.messages[rule.field]) {
        formErrors.messages[rule.field] = rule.messageError;
      }
    });
    formErrors.hasErrors = rules.some(rule => rule.hasErrors());
    setErrors(formErrors);
    return !formErrors.hasErrors;
  };
  const clearError = fieldName => {
    const newErrors = _objectSpread({}, errors);
    if (fieldName) {
      delete newErrors.messages[fieldName];
    } else {
      newErrors.messages = {};
      newErrors.hasErrors = false;
    }
    setErrors(newErrors);
  };
  return {
    errors,
    validate,
    clearError
  };
};

// FIXME: remover após ajustar o eslint e o prettier
// eslint-disable-next-line prettier/prettier

export default useRulesValidation;