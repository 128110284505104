function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useState } from 'react';
import ValidationUtils from 'utils/ValidationUtils';
import MESSAGES from 'containers/EditOwnProfile/constants/MessagesConstants';
import EditProfileUtils from 'containers/EditOwnProfile/utils/EditProfileUtils';
import useFormState from 'hooks/useFormState';
const usePasswordChange = () => {
  const methods = useFormState(EditProfileUtils.getInitialPasswordFormState());
  const [passwordErrors, setPasswordErrors] = useState(EditProfileUtils.getInitialPasswordErrors());
  const [showPasswords, setShowPasswords] = useState(EditProfileUtils.getInitialPasswordShowState());
  const togglePasswordShow = passwordToShow => {
    setShowPasswords(prev => _objectSpread(_objectSpread({}, prev), {}, {
      [passwordToShow]: !prev[passwordToShow]
    }));
  };
  const {
    form
  } = methods;
  const hasErrorsInForm = () => {
    const actualErrors = EditProfileUtils.getInitialPasswordErrors();
    if (!form.currentPassword) {
      actualErrors.hasError = true;
      actualErrors.currentPassword.message = MESSAGES.CURRENT_PASSWORD_IS_REQUIRED;
    }
    if (!form.confirmNewPassword) {
      actualErrors.hasError = true;
      actualErrors.confirmNewPassword.message = MESSAGES.PASSWORD_CONFIRMATION_IS_REQUIRED;
    }
    if (!ValidationUtils.isValidPassword(form.confirmNewPassword)) {
      actualErrors.hasError = true;
      actualErrors.confirmNewPassword.message = MESSAGES.PASSWORD_IS_INVALID;
    }
    if (!form.newPassword) {
      actualErrors.hasError = true;
      actualErrors.newPassword.message = MESSAGES.NEW_PASSWORD_IS_REQUIRED;
    }
    if (!ValidationUtils.isValidPassword(form.newPassword)) {
      actualErrors.hasError = true;
      actualErrors.newPassword.message = MESSAGES.PASSWORD_IS_INVALID;
    }
    if (form.newPassword !== form.confirmNewPassword) {
      actualErrors.hasError = true;
      actualErrors.confirmNewPassword.message = MESSAGES.PASSWORDS_ARE_DIFFERENT;
    }
    if (form.currentPassword === form.confirmNewPassword) {
      actualErrors.hasError = true;
      actualErrors.confirmNewPassword.message = MESSAGES.PASSWORD_MUST_BE_DIFFERENT_ACTUAL;
    }
    setPasswordErrors(prev => _objectSpread(_objectSpread({}, prev), actualErrors));
    return actualErrors.hasError || passwordErrors.hasError;
  };
  const handlePreventDefault = event => event.preventDefault();
  return _objectSpread(_objectSpread({}, methods), {}, {
    showPasswords,
    togglePasswordShow,
    passwordErrors,
    hasErrorsInForm,
    handlePreventDefault
  });
};
export default usePasswordChange;