function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useState, useEffect, useCallback } from 'react';
import StringUtils from 'utils/StringUtils';
import { removeLeadingZeroes, removeNonNumerics, removeNonNumericsExceptDash, stringToCurrency } from 'utils/currencyUtils';
const DefaultFormatProps = {
  precision: 2,
  style: 'currency',
  locale: 'pt-BR',
  i18nCurrency: 'BRL',
  allowNegative: true,
  alwaysNegative: false,
  onChangeCallBack: () => {},
  onBlurCallBack: () => {}
};
function useCurrencyFormat(value, defaultValue, options) {
  const [currency, setCurrency] = useState('');
  const optionsConfig = _objectSpread(_objectSpread({}, DefaultFormatProps), options);
  const formatCurrency = useCallback(rawVal => {
    const {
      precision,
      style,
      locale,
      i18nCurrency
    } = optionsConfig;
    return Number(stringToCurrency(rawVal, precision)).toLocaleString(locale, {
      style,
      currency: i18nCurrency,
      minimumIntegerDigits: 1,
      minimumFractionDigits: precision
    });
  }, [optionsConfig]);
  const normalize = value => {
    const {
      allowNegative
    } = optionsConfig;
    let newValue = value;
    if (!allowNegative) {
      newValue = newValue.replace(/-/g, '');
    }
    return newValue;
  };
  const initializeValue = function () {
    let initialValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '0';
    const {
      alwaysNegative,
      precision,
      allowNegative,
      style,
      i18nCurrency
    } = optionsConfig;
    let maskedValue = '';
    const getOperator = value => String(value).charAt(0) === '-' && allowNegative || alwaysNegative ? '-' : '';
    if (typeof initialValue === 'string') {
      maskedValue = "".concat(getOperator(initialValue)).concat(formatCurrency(removeNonNumerics(initialValue)));
    } else {
      let numericValue = initialValue;
      if (alwaysNegative && numericValue > 0) {
        numericValue *= -1;
      }
      maskedValue = StringUtils.formatFloatToCurrency(numericValue, {
        style,
        i18nCurrency,
        alwaysNegative,
        allowNegative,
        precision
      });
    }
    return maskedValue;
  };
  useEffect(() => {
    if (defaultValue !== undefined) setCurrency(initializeValue(defaultValue));
  }, [defaultValue, optionsConfig.precision]);
  useEffect(() => {
    if (value !== undefined) setCurrency(initializeValue(value));
  }, [value, optionsConfig.precision]);
  const callbackChange = useCallback((inputElement, maskedValue) => {
    const {
      precision,
      onChangeCallBack
    } = optionsConfig;
    const numericValue = Number(stringToCurrency(maskedValue, precision));
    setCurrency(maskedValue);
    onChangeCallBack(inputElement, numericValue, maskedValue);
  }, [optionsConfig]);
  const callbackBlur = useCallback((inputElement, maskedValue) => {
    const {
      precision,
      onBlurCallBack
    } = optionsConfig;
    const numericValue = Number(stringToCurrency(maskedValue, precision));
    setCurrency(maskedValue);
    onBlurCallBack(inputElement, numericValue, maskedValue);
  }, [optionsConfig]);
  const onChange = useCallback(e => {
    e.preventDefault();
    const {
      value
    } = e.currentTarget;
    const normalized = normalize(value);
    const maskedValue = formatCurrency(removeNonNumericsExceptDash(normalized));
    callbackChange(e.currentTarget, maskedValue);
  }, [callbackChange, formatCurrency]);
  const onBlur = useCallback(e => {
    e.preventDefault();
    const {
      value
    } = e.currentTarget;
    const normalized = normalize(value);
    const maskedValue = formatCurrency(removeNonNumericsExceptDash(normalized));
    callbackBlur(e.currentTarget, maskedValue);
  }, [callbackChange, formatCurrency]);
  const onKeyDown = useCallback(e => {
    if (['Backspace', 'Delete'].includes(e.key)) {
      e.preventDefault();
      const {
        precision,
        alwaysNegative
      } = optionsConfig;
      const sanitized = removeLeadingZeroes(removeNonNumerics(currency));
      let newValue = '';
      if (sanitized.length > 1) {
        const operation = currency.charAt(0) === '-' ? '-' : '';
        const value = "".concat(operation).concat(sanitized.substring(0, sanitized.length - 1).replace(/-/g, ''));
        newValue = formatCurrency(value);
      } else {
        const zeros = formatCurrency(''.padStart(precision, '0'));
        newValue = "".concat(alwaysNegative ? '-' : '').concat(zeros);
      }
      const maskedValue = formatCurrency(newValue);
      callbackChange(e.currentTarget, maskedValue);
    }
  }, [currency, optionsConfig, callbackChange, formatCurrency]);
  return [currency, Number(stringToCurrency(currency, optionsConfig.precision)), onChange, onBlur, onKeyDown];
}
export default useCurrencyFormat;