import MenuRoot from './MenuRoot';
import NotificationsMenuIcon from 'components/NotificationsMenu/components/MenuIcon';
import MenuContainer from 'components/NotificationsMenu/components/MenuContainer';
import MenuHeader from 'components/NotificationsMenu/components/MenuHeader';
import MenuTabs from 'components/NotificationsMenu/components/MenuTabs';
import MenuContent from 'components/NotificationsMenu/components/MenuContent';
const NotificationsMenuRoot = {
  Container: MenuRoot,
  Icon: NotificationsMenuIcon,
  Menu: MenuContainer,
  Header: MenuHeader,
  Tabs: MenuTabs,
  Content: MenuContent
};
export default NotificationsMenuRoot;