function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AULoadingManager from 'components/Loading/LoadingManager';
import AuthUtils from 'utils/AuthUtils';

// FIXME: os tipos para o route e data serão criados depois

const useRoute = _ref => {
  let {
    loadingBeforeRenderRoutes,
    route,
    handleCallback
  } = _ref;
  const location = useLocation();
  const [state, setState] = useState({
    readyToRender: false,
    isAuthenticated: false,
    isLoading: false,
    lastPath: undefined
  });
  const path = route.path || route.link;
  const handleAuthenticated = useCallback(async () => {
    const loadingActivated = () => {
      return loadingBeforeRenderRoutes && (typeof route.loadingBeforeRender === 'boolean' ? route.loadingBeforeRender : true);
    };
    if (!state.isLoading) {
      if (loadingActivated()) {
        AULoadingManager.show();
      }
      await AuthUtils.isAuthenticated({
        enableSessionExpiredMessageEnable: Boolean(AuthUtils.getToken())
      }).then(async _ref2 => {
        let {
          isAuthenticated
        } = _ref2;
        if (AuthUtils.getUser().isBlockedByMFAValidation()) {
          await AuthUtils.logout(false, true);
          setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
            lastPath: path,
            isAuthenticated,
            readyToRender: true
          }));
          return false;
        }
        setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
          lastPath: path,
          isAuthenticated,
          readyToRender: true
        }));
      }).finally(() => {
        if (loadingActivated()) {
          AULoadingManager.close();
        }
        setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
          isLoading: false
        }));
      });
    }
  }, [path]);
  useEffect(() => {
    handleAuthenticated();
    return () => {
      setState({
        readyToRender: false,
        isLoading: false,
        isAuthenticated: false,
        lastPath: undefined
      });
    };
  }, []);
  useEffect(() => {
    (async () => {
      await AuthUtils.lazyRefreshToken();
    })();
  }, [location.pathname]);
  return {
    state,
    location
  };
};
export default useRoute;