function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import PubSub from 'pubsub-js';
import nanoid from 'nanoid';
import ArrayUtils from 'utils/ArrayUtils';
import CONSTANTS from 'constants/notifications';
import { NOTIFIER_TYPE_CONNECTIVITY } from './types';
const prepareNotifyOption = (variant, options) => {
  return {
    options: _objectSpread(_objectSpread({
      preventDuplicate: true,
      autoHideDuration: 3000
    }, options), {}, {
      variant
    })
  };
};
class Notifier {
  constructor() {
    var _this = this;
    _defineProperty(this, "notifications", void 0);
    _defineProperty(this, "subscribeShow", callback => {
      return PubSub.subscribe(CONSTANTS.NOTIFY_ADD_NOTIFICATION, (_, notification) => {
        callback(notification);
      });
    });
    _defineProperty(this, "subscribeClose", callback => {
      return PubSub.subscribe(CONSTANTS.NOTIFY_CLOSE_NOTIFICATION, (_, key) => {
        callback(key);
      });
    });
    _defineProperty(this, "unsubscribe", subscription => {
      if (subscription) {
        PubSub.unsubscribe(subscription);
      }
    });
    _defineProperty(this, "isNotifierTypeConnectivity", notification => {
      return notification.type && notification.type === NOTIFIER_TYPE_CONNECTIVITY;
    });
    _defineProperty(this, "removeNotificationsOfTypeConnectivity", () => {
      const notificationsOfConnectivity = this.notifications.filter(this.isNotifierTypeConnectivity);
      notificationsOfConnectivity.forEach(notification => {
        this.removeNotification(notification);
      });
    });
    _defineProperty(this, "notify", notification => {
      if (this.isNotifierTypeConnectivity(notification)) {
        this.removeNotificationsOfTypeConnectivity();
      }
      const newNotification = _objectSpread({
        id: nanoid(),
        show: false
      }, notification);
      this.notifications.push(newNotification);
      PubSub.publish(CONSTANTS.NOTIFY_ADD_NOTIFICATION, this.notifications);
      return newNotification;
    });
    _defineProperty(this, "findNotificationByParam", (param, value) => {
      return this.notifications.find(notification => notification[param] === value);
    });
    _defineProperty(this, "closeNotificationByKey", key => {
      if (key) {
        PubSub.publish(CONSTANTS.NOTIFY_CLOSE_NOTIFICATION, key);
        const notification = this.findNotificationByParam('key', key);
        if (notification) this.removeNotification(notification);
      }
    });
    _defineProperty(this, "closeNotificationById", id => {
      const notification = this.findNotificationByParam('id', id);
      if (notification && notification.key) {
        this.closeNotificationByKey(notification.key);
      }
    });
    _defineProperty(this, "removeNotification", notification => {
      try {
        ArrayUtils.removeByParams(this.notifications, {
          id: notification.id
        });
      } catch (e) {
        console.error(e);
      }
    });
    _defineProperty(this, "updateNotification", notification => {
      try {
        ArrayUtils.replaceByParams(this.notifications, {
          id: notification.id
        }, notification);
      } catch (e) {
        console.error(e);
      }
    });
    _defineProperty(this, "success", function () {
      let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 ? arguments[1] : undefined;
      return _this.notify(_objectSpread({
        message
      }, prepareNotifyOption('success', options)));
    });
    _defineProperty(this, "error", function () {
      let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 ? arguments[1] : undefined;
      return _this.notify(_objectSpread({
        message
      }, prepareNotifyOption('error', _objectSpread({
        autoHideDuration: 5000
      }, options))));
    });
    _defineProperty(this, "info", function () {
      let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 ? arguments[1] : undefined;
      return _this.notify(_objectSpread({
        message
      }, prepareNotifyOption('info', options)));
    });
    _defineProperty(this, "warning", function () {
      let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 ? arguments[1] : undefined;
      return _this.notify(_objectSpread({
        message
      }, prepareNotifyOption('warning', options)));
    });
    this.notifications = [];
  }
}
export default new Notifier();