function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import PubSub from 'pubsub-js';
import _isUndefined from 'lodash/isUndefined';
import UserGroupModel from 'models/UserGroupModel';
import AccessDaysUtils from 'utils/accessDaysUtils';
import AuthUtils from 'utils/AuthUtils';
import GroupService from 'services/GroupService';
import history from 'utils/browserHistory';
import AULoadingManager from '../../../components/Loading/LoadingManager';
import AUNotifier from '../../../components/Notification/Notifier';
import ErrorUtils from '../../../utils/ErrorUtils';
import { changedLabelPath, changedLabelPathSaveForm, prepareUrl, removeHoursNull } from '../utils/UserGroupUtils';
const NOTIFY_USERGROUP = '@assertiva-ui/NOTIFY_USERGROUP';
const userProfileItem = {
  idPerfil: '',
  emails: []
};
const FlagsPermission = {
  CREATE_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:CRIAR', 'ADMINISTRATIVO:GRUPOS:CRIAR:EMPRESA'],
  UPDATE_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:EDITAR', 'ADMINISTRATIVO:GRUPOS:EDITAR:EMPRESA', 'ADMINISTRATIVO:GRUPOS:EDITAR:GRUPO'],
  VIEW_GROUP: ['BACKOFFICE:CLIENTES:GRUPOS:VISUALIZAR', 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:EMPRESA', 'ADMINISTRATIVO:GRUPOS:VISUALIZAR:GRUPO']
};
class UserGroupFormStore {
  constructor() {
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", Object.freeze({
      group: new UserGroupModel(),
      limiterWeekdays: {
        start: '',
        end: ''
      },
      limiterWeekends: {
        start: '',
        end: ''
      },
      cancelDialogGroup: false,
      disabledWeekdaysDateRange: false,
      disabledWeekendsDateRange: false,
      disabledUserGroupProfile: false,
      defaultGroups: []
    }));
    _defineProperty(this, "state", _objectSpread({}, this.initialState));
    _defineProperty(this, "notify", () => {
      PubSub.publish(NOTIFY_USERGROUP, _objectSpread({}, this.state));
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(NOTIFY_USERGROUP, (_, state) => {
        callback(state);
      });
      this.notify();
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "reset", () => {
      this.state = _objectSpread({}, this.initialState);
      this.notify();
    });
    _defineProperty(this, "updateGroup", (name, value, regex) => {
      if (regex) {
        if (regex().regex.test(value)) {
          this.state.group[name] = value;
          this.notify();
        }
      } else {
        this.state.group[name] = value;
        this.notify();
      }
    });
    _defineProperty(this, "validate", () => {
      this.state.group.validateConsumptionLimit();
      this.notify();
    });
    _defineProperty(this, "validateName", () => {
      this.state.group.validateGroupName();
      this.notify();
    });
    _defineProperty(this, "updateState", (name, value) => {
      this.state[name] = value;
      this.notify();
    });
    _defineProperty(this, "updateGroupAccessDays", (attr, value) => {
      this.state.group.days.find(val => val.day === attr).active = value;
      this.notify();
    });
    _defineProperty(this, "updateGroupNestedModel", (type, index, attr, value) => {
      this.state.group[type][index][attr] = value;
      this.notify();
    });
    _defineProperty(this, "updateConfirmDialog", (isOpen, customerId) => {
      this.state.cancelDialogGroup = isOpen;
      history.push("/".concat(changedLabelPath(customerId)).concat(prepareUrl(customerId)).concat(changedLabelPathSaveForm(customerId), "/grupos"));
      this.notify();
    });
    _defineProperty(this, "addUserProfileItem", () => {
      this.disablingProfile();
      this.state.group.users.push(_objectSpread({}, userProfileItem));
      this.notify();
    });
    _defineProperty(this, "removeProfileItem", obj => {
      this.enablingProfile(obj);
      if (this.state.group.users.length <= 1) {
        this.state.group.users = [_objectSpread({}, userProfileItem)];
      } else {
        const newArray = this.state.group.users.filter(e => e !== obj);
        this.state.group.users = newArray.map(value => ({
          idPerfil: value.idPerfil,
          emails: value.emails
        }));
      }
      this.notify();
    });
    _defineProperty(this, "enablingProfile", obj => {
      if (obj.idPerfil !== '') {
        this.state.group.profiles.find(e => e.id === obj.idPerfil).disabled = false;
      }
    });
    _defineProperty(this, "disablingProfile", () => {
      const {
        idPerfil
      } = this.state.group.users[this.state.group.users.length - 1];
      const profile = this.state.group.profiles.find(e => e.id === idPerfil);
      if (!_isUndefined(profile)) {
        profile.disabled = true;
      }
    });
    _defineProperty(this, "getSelectData", async _ref => {
      let {
        customerId
      } = _ref;
      try {
        const res = await GroupService.getSelectData({
          customerId
        }).catch(error => {
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
        });
        return res && res.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    });
    _defineProperty(this, "getGroupById", async _ref2 => {
      let {
        customerId,
        groupId
      } = _ref2;
      try {
        const res = await GroupService.getGroupById({
          customerId,
          groupId
        }).catch(error => {
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
        });
        return res && res.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    });
    _defineProperty(this, "saveGroup", async _ref3 => {
      let {
        customerId,
        groupId,
        onPostSaveGroup
      } = _ref3;
      AULoadingManager.show();
      const params = this.state.group.toParams(groupId);
      const httpMethod = groupId ? 'putGroup' : 'postGroup';
      const methodArgs = groupId ? {
        customerId,
        groupId,
        params
      } : {
        customerId,
        params
      };
      try {
        const response = await GroupService
        // eslint-disable-next-line no-unexpected-multiline
        [httpMethod](methodArgs).catch(error => {
          AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
          AULoadingManager.close();
        });
        const {
          data
        } = response;
        if (data) {
          AUNotifier.success(data.message);
        }
        if (onPostSaveGroup) onPostSaveGroup();
        AULoadingManager.close();
      } catch (error) {
        AULoadingManager.close();
      }
    });
    _defineProperty(this, "init", async _ref4 => {
      let {
        group,
        groupId,
        customerId,
        viewMode
      } = _ref4;
      AULoadingManager.show();
      this.state.group = group;
      const selectData = await this.getSelectData({
        customerId
      });
      try {
        if (selectData) {
          const {
            limitationCustomer,
            accessLimitation,
            profiles
          } = selectData;
          this.state.group.limitationCustomer = limitationCustomer;
          this.state.group.consumptionLimit = limitationCustomer;
          const customProfiles = profiles.map(v => _objectSpread(_objectSpread({}, v), {}, {
            disabled: false
          }));
          this.state.group.profiles = customProfiles;
          if (groupId) {
            const groupData = await this.getGroupById({
              customerId,
              groupId
            });
            AULoadingManager.close();
            this.state.group.status = this.state.group.setStatus(groupData.status);
            this.state.group.groupName = groupData.name;
            this.state.group.consumptionLimit = groupData.limitationPrice;
            this.state.group.ips = groupData.ips;
            const defaultGroup = {
              name: groupData.name || '',
              id: Number(groupId)
            };
            this.state.defaultGroups = [defaultGroup];
            this.state.group.users = groupData.users.map(user => _objectSpread(_objectSpread({}, user), {}, {
              group: defaultGroup
            }));
            this.initDateRangeAccessLimitation(removeHoursNull(groupData.accessLimitation));
            this.setLimiterDateRange(accessLimitation);
            if (!viewMode) {
              this.state.group.days = groupData.accessLimitation.map((v, id) => _objectSpread(_objectSpread({}, v), {}, {
                startActiveDays: !accessLimitation[id].active,
                active: !accessLimitation[id].active ? accessLimitation[id].active : v.active
              }));
            } else {
              this.state.group.days = accessLimitation.map(v => _objectSpread({}, v));
            }
          } else {
            AULoadingManager.close();
            this.state.group.days = accessLimitation.map(v => _objectSpread(_objectSpread({}, v), {}, {
              startActiveDays: !v.active
            }));
            this.state.group.users = [_objectSpread({}, userProfileItem)];
            this.setLimiterDateRange(accessLimitation);
            this.initDateRangeAccessLimitation(accessLimitation);
          }
          this.notify();
        }
      } catch (error) {
        console.error(error);
      }
      AULoadingManager.close();
    });
    _defineProperty(this, "initDateRangeAccessLimitation", accessLimitation => {
      AccessDaysUtils.setHoursAccessLimitation(accessLimitation, 'segunda', 'startTime', 'accessStartTime', (key, value) => {
        this.state.group[key] = value;
      });
      AccessDaysUtils.setHoursAccessLimitation(accessLimitation, 'segunda', 'endTime', 'accessEndTime', (key, value) => {
        this.state.group[key] = value;
      });
      AccessDaysUtils.setHoursAccessLimitation(accessLimitation, 'sabado', 'startTime', 'accessStartTimeWeekends', (key, value) => {
        this.state.group[key] = value;
      });
      AccessDaysUtils.setHoursAccessLimitation(accessLimitation, 'sabado', 'endTime', 'accessEndTimeWeekends', (key, value) => {
        this.state.group[key] = value;
      });
    });
    _defineProperty(this, "setLimiterDateRange", accessLimitation => {
      this.state.limiterWeekdays = this.getLimiterDateRange(accessLimitation).weekdays;
      this.state.limiterWeekends = this.getLimiterDateRange(accessLimitation).weekends;
    });
    _defineProperty(this, "getLimiterDateRange", accessLimitation => ({
      weekdays: {
        start: AccessDaysUtils.setHoursAccessLimitation(accessLimitation, 'segunda', 'startTime'),
        end: AccessDaysUtils.setHoursAccessLimitation(accessLimitation, 'segunda', 'endTime')
      },
      weekends: {
        start: AccessDaysUtils.setHoursAccessLimitation(accessLimitation, 'sabado', 'startTime'),
        end: AccessDaysUtils.setHoursAccessLimitation(accessLimitation, 'sabado', 'endTime')
      }
    }));
    _defineProperty(this, "hasUpdateGroupByPermission", () => AuthUtils.getUser().hasPermission(FlagsPermission.UPDATE_GROUP));
    _defineProperty(this, "hasViewGroupByPermission", () => AuthUtils.getUser().hasPermission(FlagsPermission.VIEW_GROUP));
  }
}
export default new UserGroupFormStore();