function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export const FEATURE_FLAG_CONFIG_DEV = {
  url: 'https://parquinho-k8s-dev.assertivasolucoes.com.br/unleash-proxy/proxy',
  clientKey: 'f888e9ab-ca9e-48d9-bfb5-e92bbac19d09',
  refreshInterval: 60,
  appName: 'fcc-flagging',
  environment: 'dev'
};
export const FEATURE_FLAG_CONFIG_PROD = {
  url: 'https://portalv3-k8s-prd.assertivasolucoes.com.br/unleash-proxy/proxy',
  clientKey: '07a572d9-db8b-460c-aa7c-2902bfee2221',
  refreshInterval: 60,
  appName: 'fcc-flagging',
  environment: 'prod'
};
export const GET_FEATURE_FLAG_CONFIG = () => {
  const {
    REACT_APP_APP_ENV
  } = process.env;
  if (REACT_APP_APP_ENV === 'production') {
    return _objectSpread({}, FEATURE_FLAG_CONFIG_PROD);
  }
  return _objectSpread({}, FEATURE_FLAG_CONFIG_DEV);
};