var _StringUtils;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import moment from 'moment';
import VanillaMasker from 'vanilla-masker';
import _get from 'lodash/get';
import _kebabCase from 'lodash/kebabCase';
import mask from 'constants/masks';
const defaultCurrencyOptions = {
  precision: 2,
  style: 'currency',
  locale: 'pt-BR',
  i18nCurrency: 'BRL',
  allowNegative: true,
  alwaysNegative: false
};
class StringUtils {
  static capitalize() {
    let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return (string || '').toString().toLowerCase().replace(/^[a-zA-Zà-Ź0-9]/, c => c.toUpperCase());
  }

  // TODO: Verificar quais produtos usam essa função, talvez seja melhor remover ela e usar a camelizeNoSpecialCharacteres no lugar
  static camelize() {
    let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let keepSpace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const string = (str || '').split(' ').map(s => StringUtils.capitalize(s)).join(' ');
    return keepSpace ? string : string.replace(' ', '');
  }

  /**
   * 1 - Capitaliza a primeira letra de cada palavra
   *
   * 2 - Remove espaços (opcional)
   *
   * 3 - Remove acentos (opcional)
   *
   * 4 - Remove o caractere enviado no parâmetro removeChar (o padrão é não remover nenhum caractere)
   *
   * Exemplo: camelizeNoSpecialCharacteres('Um idoso ex-empreendedor próximo a metrópole', '-', false, true) => UmIdosoExEmpreendedorProximoAMetropole
   */
  static camelizeNoSpecialCharacteres(str) {
    let removeChar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    let keepSpace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let removeAccents = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    const string = str.toLowerCase().replace(/(?:^|\s|[-"'([{])+\S/g, c => c.toUpperCase()); // Capitaliza a primeira letra de cada palavra
    const regexRemoveSpacesAndCharacter = new RegExp("[".concat(keepSpace ? '' : ' ').concat(removeChar, "]"), 'g'); // Remove espaços (se keepSpace for true) e o caractere enviado no parâmetro removeChar

    if (removeAccents) return this.removeAccents(string).replace(regexRemoveSpacesAndCharacter, '');
    return string.replace(regexRemoveSpacesAndCharacter, '');
  }
  static formatElementID() {
    let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    const clearSpecialCharacters = string.replace(/[^\w\s]/gi, '');
    const replaceSpaceToHyphen = clearSpecialCharacters.replace(/\s+/g, '-');
    return replaceSpaceToHyphen.toLowerCase();
  }
  static removeAccents() {
    let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return (string || '').toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
_StringUtils = StringUtils;
_defineProperty(StringUtils, "toUpper", function () {
  let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return string.toUpperCase();
});
/**
 * @deprecated Por favor usar  formatFloatToCurrency.
 */
_defineProperty(StringUtils, "formatFloatToCurrencyPtBr", function () {
  let float = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  console.warn('formatFloatToCurrencyPtBr is deprecated, please use formatFloatToCurrency');
  const format = value => value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: precision
  });
  try {
    return format(float);
  } catch (e) {
    console.error('formatFloatToCurrencyPtBr: ', e);
    return format(0);
  }
});
_defineProperty(StringUtils, "formatCurrencyToFloatPtBr", function () {
  let currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '0';
  let currencyOptions = arguments.length > 1 ? arguments[1] : undefined;
  const options = _objectSpread(_objectSpread({}, defaultCurrencyOptions), currencyOptions);
  const normalizeCurrency = () => currency.replace(/[^\d.,-]+/g, '').replace(/\./g, '').replace(/,/g, '.').trim();
  try {
    return +parseFloat(normalizeCurrency()).toFixed(options.precision);
  } catch (e) {
    console.error('formatCurrencyToFloatPtBr: ', e);
    return 0;
  }
});
_defineProperty(StringUtils, "formatFloatToCurrency", function () {
  let float = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let currencyOptions = arguments.length > 1 ? arguments[1] : undefined;
  const options = _objectSpread(_objectSpread({}, defaultCurrencyOptions), currencyOptions);
  const format = value => value.toLocaleString(options.locale, _objectSpread(_objectSpread({}, options), {}, {
    currency: options.i18nCurrency,
    minimumFractionDigits: options.precision
  }));
  try {
    return format(float);
  } catch (e) {
    console.error('formatFloatToCurrency: ', e);
    return format(0);
  }
});
_defineProperty(StringUtils, "resolveAllDynamicTypePhone", function () {
  let phone = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (phone.startsWith('0')) return mask.phone_0800.br_default;
  if (phone.length <= 10) return mask.landline_phone.br_default;
  return mask.mobile_phone.br_default;
});
_defineProperty(StringUtils, "formatPhone", function () {
  let phone = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  const value = (phone || '').toString().replace(/\D/g, '');
  switch ((type || '').toUpperCase()) {
    case 'MOBILE':
      return VanillaMasker.toPattern(value, mask.mobile_phone.br_default);
    case 'LANDLINE':
      return VanillaMasker.toPattern(value, mask.landline_phone.br_default);
    case '0800':
      return VanillaMasker.toPattern(value, mask.phone_0800.br_default);
    case 'ALL':
      return VanillaMasker.toPattern(value, _StringUtils.resolveAllDynamicTypePhone(value));
    default:
      {
        return VanillaMasker.toPattern(value, value.length <= 10 ? mask.landline_phone.br_default : mask.mobile_phone.br_default);
      }
  }
});
_defineProperty(StringUtils, "formatDocument", function () {
  let document = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  const value = (document || '').toString().replace(/\D/g, '');
  switch ((type || '').toUpperCase()) {
    case 'CPF':
      return VanillaMasker.toPattern(value, mask.document.cpf);
    case 'CNPJ':
      return VanillaMasker.toPattern(value, mask.document.cnpj);
    default:
      {
        return VanillaMasker.toPattern(value, value.length <= 11 ? mask.document.cpf : mask.document.cnpj);
      }
  }
});
_defineProperty(StringUtils, "formatZipCode", function () {
  let cep = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let autoCompleteWithZero = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  const value = (cep || '').toString().replace(/\D/g, '');
  return VanillaMasker.toPattern(value.length < 8 && autoCompleteWithZero ? value.padStart(8, '0') : value, mask.cep);
});
/**
 * @deprecated Por favor usar  formatZipCode.
 */
_defineProperty(StringUtils, "formatCep", function () {
  let cep = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let autoCompleteWithZero = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return _StringUtils.formatZipCode(cep, autoCompleteWithZero);
});
_defineProperty(StringUtils, "formatDecimalToCurrencyPtBr", decimal => {
  // TODO: missing unity test and throws
  let tmp = decimal.toString();
  tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
  if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return "R$ ".concat(tmp);
});
_defineProperty(StringUtils, "formatMoney", function (number) {
  let precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  const string = String(number);
  let divider = '1';
  for (let index = 0; index < precision; index += 1) {
    divider = divider.concat('0');
  }
  const maskMoney = value => value.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,').replace(/(\d)(\d{3}),/g, '$1.$2,');
  const handleMoneyToFloat = e => {
    const digits = e.replace(/\D/g, '');
    return "".concat(digits / divider);
  };
  const handleFloatToMoney = e => {
    const value = parseFloat(e).toFixed(precision).split('.');
    value[0] = "".concat(value[0].split(/(?=(?:...)*$)/).join('.'));
    return value.join(',');
  };
  const handleMoney = value => {
    return maskMoney(handleMoneyToFloat(value).replace('.', ','));
  };
  return {
    maskMoney: handleMoney(string),
    convertMoneyToFloat: handleMoneyToFloat(string),
    convertFloatToMoney: handleFloatToMoney(string)
  };
});
_defineProperty(StringUtils, "returnOnlyNumber", text => {
  return text.replace(/\D/g, '');
});
_defineProperty(StringUtils, "reverse", string => {
  if (!string) return '';
  return string.split('').reverse().join('');
});
/**
 *
 * @param string string para padronizar em lowercase-hyphen, ex: Monitor de envios
 * @returns uma string no formato: monitor-de-envios
 */
_defineProperty(StringUtils, "formatToLowerCaseHyphen", string => {
  if (!string) return '';
  return string.replace(/[^a-zA-ZÀ-ÿ0-9\s]/g, '').trim().toLowerCase().split(' ').join('-');
});
_defineProperty(StringUtils, "ellipsisStringAtChar", (string, char) => {
  if (!string) return '';
  if (string.length <= char) return string;
  return "".concat(string.slice(0, char), "...");
});
_defineProperty(StringUtils, "protectText", function () {
  let char = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '*';
  let size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  return char.repeat(size);
});
_defineProperty(StringUtils, "abbreviatePhrase", (phrase, options) => {
  const defaultSplitSeparator = _get(options, 'splitSeparator', ' ');
  const defaultSliceStart = _get(options, 'sliceStart', 0);
  const defaultSliceEnd = _get(options, 'sliceEnd', 3);
  const defaultJoinSeparator = _get(options, 'joinSeparator', ' ');
  return phrase.split(defaultSplitSeparator).map(world => world.slice(defaultSliceStart, defaultSliceEnd)).join(defaultJoinSeparator);
});
_defineProperty(StringUtils, "standarizeId", function () {
  let idName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let options = arguments.length > 1 ? arguments[1] : undefined;
  const defaultPrefix = _get(options, 'prefix', '');
  const defaultSufix = _get(options, 'sufix', '');
  return _kebabCase("".concat(defaultPrefix, " ").concat(idName, " ").concat(defaultSufix));
});
_defineProperty(StringUtils, "generatePreviousMonthsArray", monthsAmout => {
  const previousMonths = [];
  for (let previousMonth = 0; previousMonth < monthsAmout; previousMonth++) {
    previousMonths.push({
      endDate: previousMonth == 0 ? moment() : moment().subtract(previousMonth, 'months').endOf('month'),
      monthName: moment().subtract(previousMonth, 'months').format('MMMM'),
      monthNumber: moment().subtract(previousMonth, 'months').format('M'),
      startDate: moment().subtract(previousMonth, 'months').startOf('month')
    });
  }
  return previousMonths;
});
export default StringUtils;