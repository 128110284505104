function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import PubSub from 'pubsub-js';
import CONSTANTS from 'constants/notifications';
import { LoadingParams } from './types';
class LoadingManager {
  constructor() {
    var _this = this;
    _defineProperty(this, "loadings", void 0);
    _defineProperty(this, "subscribe", callback => {
      return PubSub.subscribe(CONSTANTS.NOTIFY_LOADING_UPDATE, (_, data) => {
        callback(data);
      });
    });
    _defineProperty(this, "unsubscribe", subscription => {
      if (subscription) {
        PubSub.unsubscribe(subscription);
      }
    });
    _defineProperty(this, "notify", function () {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const loadingParams = new LoadingParams(options);
      PubSub.publish(CONSTANTS.NOTIFY_LOADING_UPDATE, _objectSpread({
        isLoading: Boolean(_this.findLoadingByName(loadingParams.name)),
        count: _this.countLoadings(loadingParams.name)
      }, loadingParams));
    });
    _defineProperty(this, "countLoadings", name => {
      return this.loadings.filter(item => item.name === name).length;
    });
    _defineProperty(this, "findLoadingByName", function () {
      let name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return _this.loadings.find(item => item.name === name);
    });
    _defineProperty(this, "show", function () {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const loadingParams = new LoadingParams(options);
      _this.loadings.push(loadingParams);
      _this.notify(loadingParams);
    });
    _defineProperty(this, "close", function () {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const loadingParams = new LoadingParams(options);
      if (_this.loadings.length >= 1) {
        const loadingOptions = _this.findLoadingByName(loadingParams.name);
        if (loadingOptions) {
          const index = _this.loadings.indexOf(loadingOptions);
          if (index !== -1) {
            _this.loadings.splice(index, 1);
            _this.notify(loadingOptions);
            return;
          }
        }
      }
      _this.notify();
    });
    this.loadings = [];
  }
}
export default new LoadingManager();