import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
export const StyledTabsContainer = styled(Box)(_ref => {
  let {
    theme
  } = _ref;
  return {
    display: 'flex',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    '& .fixedTabsContainer': {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }
    },
    '& .dynamicTabsContainer': {
      width: '100%',
      paddingRight: 48,
      '& .MuiTab-wrapper': {
        display: 'block'
      }
    },
    '& .fixedTabsContainer + .dynamicTabsContainer': {
      width: '83%',
      overflow: 'auto',
      [theme.breakpoints.down('lg')]: {
        width: '80%'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    '& .MuiTabScrollButton-root svg': {
      fill: theme.palette.primary.main
    }
  };
});