import { styled } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
export const StyledMenuContent = styled(Box)({
  maxHeight: 450,
  overflowY: 'auto',
  '& .see-more': {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10
  }
});
export const StyledCircularProgress = styled(CircularProgress)({
  color: '#c6c6c6'
});