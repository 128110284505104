function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import PubSub from 'pubsub-js';
import ProfileService from 'services/ProfileService';
import ErrorUtils from 'utils/ErrorUtils';
import Notifier from 'components/Notification/Notifier';
import AULoadingManager from 'components/Loading/LoadingManager';
import AuthUtils from 'utils/AuthUtils';
import { VIEW_PERMISSIONS, VIEW_CREATE_OR_EDIT_PROFILE_COMPANY_LEVEL } from 'constants/permissions';
const AU_PROFILE_NOTIFY = 'AU_PROFILE_NOTIFY';
class ProfilesStore {
  constructor() {
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", {
      profiles: [],
      selectedProfile: {},
      openProfileDialog: false,
      isEditionMode: false,
      hasViewProfiles: () => AuthUtils.getUser().hasPermission(VIEW_CREATE_OR_EDIT_PROFILE_COMPANY_LEVEL),
      hasViewPermissions: () => AuthUtils.getUser().hasPermission(VIEW_PERMISSIONS)
    });
    _defineProperty(this, "state", _objectSpread({}, this.initialState));
    _defineProperty(this, "init", _ref => {
      let {
        customerId,
        checkCustomerIdPresence
      } = _ref;
      if (checkCustomerIdPresence && !customerId) {
        console.error('[Profiles] customerId não foi informado.');
        return;
      }
      if (this.state.hasViewProfiles()) {
        AULoadingManager.show();
        ProfileService.getCustomerProfiles({
          customerId
        }).then(response => {
          this.state.profiles = response.data;
        }).catch(error => {
          Notifier.error(ErrorUtils.normalizeErrorMessage(error));
        }).finally(() => {
          AULoadingManager.close();
          this.notify();
        });
      }
    });
    _defineProperty(this, "notify", () => {
      PubSub.publish(AU_PROFILE_NOTIFY, _objectSpread({}, this.state));
    });
    _defineProperty(this, "reset", () => {
      this.state = _objectSpread({}, this.initialState);
      this.notify();
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(AU_PROFILE_NOTIFY, (_, state) => {
        callback(state);
      });
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "toggleProfileDialog", profile => {
      this.state.openProfileDialog = !this.state.openProfileDialog;
      if (profile) {
        this.state.selectedProfile = profile;
      } else {
        this.state.selectedProfile = {};
      }
      this.notify();
    });
    _defineProperty(this, "toggleEditionMode", edit => {
      this.state.isEditionMode = edit;
    });
  }
}
export default new ProfilesStore();