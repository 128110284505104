export const NotificationsMenuTabs = [{
  label: 'Todas'
}];
export const notificationsMock = {
  list: [{
    productIds: [0],
    content: 'Notificação teste.',
    componentType: 'CustomerNotification',
    variant: 'warning',
    id: '1',
    allowedRoutes: null,
    blockedRoutes: null,
    priority: 0,
    eventCategory: null,
    eventLabel: null,
    category: 'ATUALIZAÇÕES',
    date: '2024-10-01',
    read: false
  }],
  loading: false,
  error: false
};