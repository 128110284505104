function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import RuntimeEnvs from 'utils/RuntimeEnvs';
import development from './development';
import production from './production';
import test from './test';
const getAppEnvAvailable = () => {
  switch (process.env.REACT_APP_APP_ENV) {
    /* INFO: pode ter ficado meio redundante essa case com o retorno igual ao que é testado, 
      você pode perguntar: "nao poderia simplesmente devolver o conteúdo?",
      no entanto mantivemos assim para que o retorno fosse o esperado no ts, ou seja um dos três valores
      development, production ou  test, qualquer coisa diferente será apresentado um erro.
    */
    case 'development':
      return 'development';
    case 'production':
      return 'production';
    case 'test':
      return 'test';
    default:
      throw new Error('envs: é necessário adicionar a variável REACT_APP_APP_ENV como variável de ambiente em seus arquivos .env com um dos seguintes valores: development, test ou production');
  }
};
const getEnvs = () => {
  const environments = {
    test: test(),
    development: development(),
    production: production()
  };
  return _objectSpread({}, environments[getAppEnvAvailable()]);
};
const staticEnvironments = getEnvs();

/**
 * @description
 * Você pode dar um override nas variáveis de ambientes aqui presentes criando a sua variável de ambiente utilizando o prefixo
 * REACT_APP_ASSERTIVA. Por exemplo:
 *
 * Exemplo:
 * ```
 * - REACT_APP_ASSERTIVA_API_GATEWAY_URL = 'https://apigateway-temp.assertivasolucoes.com.br'
 * - REACT_APP_ASSERTIVA_GEOTIMIZE_URL = 'https://geotimize-temp.assertivasolucoes.com.br'
 * - etc.
 *```
 * Obs: para uma lista completa das variáveis de ambientes veja o type Environments, localize em /types/environments.ts no assertiva-ui.
 *
 * Ou você pode mudar as mesmas em tempo de execução:
 *
 * ```
 *  RuntimeEnvs.configure({ ... })
 * ```
 *
 */
const Envs = () => RuntimeEnvs.hasRuntimeEnvs() ? getEnvs() : staticEnvironments;
export { Envs };