import Tab from '@material-ui/core/Tab';
import { styled } from '@material-ui/core/styles';
export const StyledTab = styled(Tab)(_ref => {
  let {
    theme
  } = _ref;
  return {
    '& .tabLabel': {
      display: 'flex',
      gridGap: 8,
      alignItems: 'center'
    },
    '& .textBox': {
      fontSize: '0.875rem',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    '& .iconText': {
      fontSize: '0.5rem',
      fontWeight: '700',
      border: '2px solid #737373',
      padding: '1px 4px',
      borderRadius: '0.25rem',
      lineHeight: '1rem',
      height: 20
    },
    '& .iconText.active': {
      border: "2px solid ".concat(theme.palette.primary.main)
    }
  };
});
export const StyledTooltip = styled('div')({
  whiteSpace: 'pre-line',
  textAlign: 'center'
});