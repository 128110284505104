function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useState, useRef } from 'react';
import useRulesValidation from 'hooks/useRulesValidation';
import ValidationUtils from 'utils/ValidationUtils';
import ErrorUtils from 'utils/ErrorUtils';
import Notifier from 'components/Notification/Notifier';
import LoadingManager from 'components/Loading/LoadingManager';
import MESSAGES from 'constants/messages';
import OAuth2Service from 'services/OAuth2Service';
const usePasswordChange = _ref => {
  let {
    hash,
    urlRedirect
  } = _ref;
  const recaptchaRef = useRef(null);
  const [state, setState] = useState({
    password: '',
    passwordCheck: '',
    loading: false,
    messages: {
      password: '',
      passwordCheck: undefined
    },
    showConfirmScreen: false,
    showPasswordCheck: false,
    showPassword: false,
    anchorEl: null,
    showPasswordValidatePopper: false
  });
  const [mfa, setMfa] = useState(null);
  const {
    errors,
    validate,
    clearError
  } = useRulesValidation([{
    field: 'password',
    messageError: 'Senha é obrigatória.',
    hasErrors: () => !state.password
  }, {
    field: 'password',
    messageError: MESSAGES.invalidRulesPassword,
    hasErrors: () => !ValidationUtils.isValidPassword(state.password)
  }, {
    field: 'passwordCheck',
    messageError: 'Confirmar senha é obrigatório.',
    hasErrors: () => !state.passwordCheck
  }, {
    field: 'passwordCheck',
    messageError: MESSAGES.invalidPasswordConfirmation,
    hasErrors: () => state.password !== state.passwordCheck
  }]);
  const onVerifiedCodeSucessMFA = () => {
    setState(_objectSpread(_objectSpread({}, state), {}, {
      password: '',
      passwordCheck: '',
      showConfirmScreen: true
    }));
  };
  const doSubmit = () => {
    LoadingManager.show();
    OAuth2Service.changePassword({
      password: state.password,
      passwordCheck: state.passwordCheck,
      hash
    }).then(response => {
      const {
        data
      } = response;
      if (data !== null && data !== void 0 && data.mfa) {
        setMfa(data);
        return;
      }
      setState(_objectSpread(_objectSpread({}, state), {}, {
        password: '',
        passwordCheck: '',
        showConfirmScreen: true
      }));
    }).catch(error => {
      Notifier.error(ErrorUtils.normalizeErrorMessage(error));
    }).finally(() => {
      LoadingManager.close();
    });
  };
  const onConfirm = () => {
    window.location.href = urlRedirect;
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (validate() && recaptchaRef) {
      recaptchaRef.current.execute();
    }
  };
  const handleState = (name, value) => {
    setState(_objectSpread(_objectSpread({}, state), {}, {
      [name]: value
    }));
  };
  const toggleValidationPopper = () => {
    setState(_objectSpread(_objectSpread({}, state), {}, {
      showPasswordValidatePopper: true
    }));
  };
  const toggleShowCheckPassword = () => {
    setState(_objectSpread(_objectSpread({}, state), {}, {
      showPasswordCheck: !state.showPasswordCheck
    }));
  };
  const toggleShowPassword = () => {
    setState(_objectSpread(_objectSpread({}, state), {}, {
      showPassword: !state.showPassword
    }));
  };
  return {
    state,
    mfa,
    setState,
    errors,
    recaptchaRef,
    clearError,
    validate,
    handleState,
    toggleValidationPopper,
    toggleShowPassword,
    toggleShowCheckPassword,
    handleSubmit,
    onVerifiedCodeSucessMFA,
    doSubmit,
    onConfirm
  };
};
export default usePasswordChange;