function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import PubSub from 'pubsub-js';
import AULoadingManager from 'components/Loading/LoadingManager';
import AUNotifier from 'components/Notification/Notifier';
import { VIEW_REPORT_PRODUCTS } from 'constants/permissions';
import ReportService from 'services/ReportService';
import AuthUtils from 'utils/AuthUtils';
import ErrorUtils from 'utils/ErrorUtils';
import ProductUtils from 'utils/ProductUtils';
const NOTIFY_UPDATE_CONSOLIDATED_CONSUMPTION_REPORT = 'NOTIFY_UPDATE_CONSOLIDATED_CONSUMPTION_REPORT';
class ConsolidatedConsumptionStore {
  constructor() {
    var _this = this;
    _defineProperty(this, "subscription", null);
    _defineProperty(this, "initialState", Object.freeze({
      reportRows: [],
      reportParams: {},
      pagination: {
        page: 0,
        rowsPerPage: 10,
        count: 0
      },
      invokedReport: false,
      isBackofficeInterno: false,
      hasProductPermission: AuthUtils.getUser().hasPermission(VIEW_REPORT_PRODUCTS),
      changedFilter: false,
      totalQuantity: 0,
      totalAmount: 0,
      totalPricePerUser: 0
    }));
    _defineProperty(this, "state", _objectSpread({}, this.initialState));
    _defineProperty(this, "notify", () => {
      PubSub.publish(NOTIFY_UPDATE_CONSOLIDATED_CONSUMPTION_REPORT, _objectSpread({}, this.state));
    });
    _defineProperty(this, "subscribe", callback => {
      this.subscription = PubSub.subscribe(NOTIFY_UPDATE_CONSOLIDATED_CONSUMPTION_REPORT, (_, state) => {
        callback(state);
      });
      this.notify();
    });
    _defineProperty(this, "unsubscribe", () => {
      if (this.subscription) {
        PubSub.unsubscribe(this.subscription);
      }
    });
    _defineProperty(this, "init", _ref => {
      let {
        reportParams
      } = _ref;
      this.state.isBackofficeInterno = ProductUtils.isBackofficeInternoEnv();
      this.state.reportParams = reportParams;
      if (!this.state.isBackofficeInterno) {
        this.handleReport();
      }
      this.notify();
    });
    _defineProperty(this, "reset", () => {
      this.state = _objectSpread({}, this.initialState);
      this.notify();
    });
    _defineProperty(this, "handleState", (name, value) => {
      this.state = _objectSpread(_objectSpread({}, this.state), {}, {
        [name]: value
      });
      this.notify();
    });
    _defineProperty(this, "handlePagination", function (attr, value) {
      let searchAfterUpdate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      _this.state.pagination = _objectSpread(_objectSpread({}, _this.state.pagination), {}, {
        [attr]: value
      });
      if (attr === 'rowsPerPage') {
        _this.state.pagination.page = 0;
      }
      if (searchAfterUpdate) {
        _this.handleReport();
      }
    });
    _defineProperty(this, "handleReportParams", params => {
      this.state.reportParams = params;
      this.state.changedFilter = true;
      this.notify();
    });
    _defineProperty(this, "handleReport", () => {
      if (this.state.changedFilter) {
        this.state.pagination.page = 0;
      }
      AULoadingManager.show();
      ReportService.getConsolidatedConsumption(_objectSpread(_objectSpread({}, this.state.reportParams), {}, {
        numPage: this.state.pagination.page,
        numPageSize: this.state.pagination.rowsPerPage
      })).then(response => {
        this.state.reportRows = [];
        this.state.pagination.page = 0;
        this.state.pagination.count = 0;
        const {
          list,
          numPage,
          totalItems,
          totalQuantity,
          totalAmount,
          totalPricePerUser
        } = response.data;
        this.state.reportRows = list;
        this.state.pagination.count = totalItems;
        this.state.pagination.page = numPage;
        this.state.changedFilter = false;
        this.state.totalQuantity = totalQuantity;
        this.state.totalAmount = totalAmount;
        this.state.totalPricePerUser = totalPricePerUser;
      }).catch(error => {
        this.state.reportRows = [];
        AUNotifier.error(ErrorUtils.normalizeErrorMessage(error));
      }).finally(() => {
        this.state.invokedReport = true;
        AULoadingManager.close();
        this.notify();
      });
    });
    _defineProperty(this, "extractReportCSV", () => {
      AULoadingManager.show();
      const {
        reportParams
      } = this.state;
      ReportService.getConsolidatedConsumptionReportCSV(reportParams).then(response => window.open(response.data.presignedUrl)).catch(error => AUNotifier.error(ErrorUtils.normalizeErrorMessage(error))).finally(() => AULoadingManager.close());
    });
    _defineProperty(this, "hasReportsRows", () => {
      return this.state.reportRows.length > 0;
    });
    _defineProperty(this, "hasTotalAmount", () => {
      return this.state.totalAmount > 0;
    });
    _defineProperty(this, "hasTotalPricePerUser", () => {
      return this.state.totalPricePerUser > 0;
    });
  }
}
export default new ConsolidatedConsumptionStore();