export function darkenColor(hex, percent) {
  if (!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(hex)) {
    throw new Error('Invalid hex color format. Use #RRGGBB or #RGB.');
  }
  const cleanedHex = hex.slice(1);
  const r = parseInt(cleanedHex.length === 3 ? cleanedHex[0] + cleanedHex[0] : cleanedHex.substring(0, 2), 16);
  const g = parseInt(cleanedHex.length === 3 ? cleanedHex[1] + cleanedHex[1] : cleanedHex.substring(2, 4), 16);
  const b = parseInt(cleanedHex.length === 3 ? cleanedHex[2] + cleanedHex[2] : cleanedHex.substring(4, 6), 16);
  const darken = c => Math.max(0, Math.min(255, Math.floor(c * (1 - percent))));
  return "#".concat([darken(r), darken(g), darken(b)].map(c => c.toString(16).padStart(2, '0')).join('').toUpperCase());
}
export function removeLabel(name) {
  return name.replace('ASSERTIVA_', '');
}
export const transformFirstCharacterLowerCase = text => {
  if (text && text.length > 0) {
    return text.charAt(0).toLowerCase() + text.slice(1);
  }
  return text;
};
export function removerUnderscore(str) {
  if (str.toLowerCase() === 'base_certa') {
    return str.replace('_', '');
  }
  return str;
}
export function handleButtonClickRedirect(AuthUtils, ProductUtils, Envs, productId, callback) {
  const productName = removerUnderscore(removeLabel(ProductUtils.getProductNameByProductId(productId)));
  const productNameLowerCase = productName.toLowerCase();
  const productUrl = Envs()["".concat(productName, "_URL")];
  const painelUrl = "".concat(Envs().PAINEL_URL, "/tenho-interesse/").concat(productNameLowerCase);
  callback(AuthUtils.getUser().hasProductById(productId), AuthUtils.getUser().hasProductById(productId) ? productUrl : painelUrl, productNameLowerCase.toUpperCase());
}