function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import RuntimeEnvs from 'utils/RuntimeEnvs';
function getProcessEnvByName(name, defaultValue) {
  return process.env["REACT_APP_ASSERTIVA_".concat(name)] || defaultValue;
}
function applyDefaultEnvsWhenFound(environments) {
  const envsRunTime = RuntimeEnvs.getEnvs();
  const envNames = Object.keys(environments);
  const newEnvironment = _objectSpread({}, environments);
  for (let i = 0; i <= envNames.length; i++) {
    const name = envNames[i];
    const currentValue = newEnvironment[name];
    if (name) {
      newEnvironment[name] = getProcessEnvByName(name, currentValue);
    }
    if (envsRunTime[name]) {
      newEnvironment[name] = envsRunTime[name];
    }
  }
  return newEnvironment;
}
function applySpecificEnvsWhenFound(environments) {
  return applyDefaultEnvsWhenFound(environments);
}
export { applyDefaultEnvsWhenFound, applySpecificEnvsWhenFound };