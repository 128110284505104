import moment from 'moment';
export function formatDateDifference(inputDate) {
  const date = moment(inputDate, ['DD/MM/YYYY', 'YYYY-MM-DD'], true);
  if (!date.isValid()) {
    throw new Error('Formato de data inválido. Use DD/MM/YYYY ou YYYY-MM-DD.');
  }
  const now = moment();
  const diffInDays = now.diff(date, 'days');
  if (diffInDays === 0) {
    return 'Hoje';
  } else if (diffInDays === 1) {
    return 'Ontem';
  } else if (diffInDays < 7) {
    return "".concat(diffInDays, " dias atr\xE1s");
  } else if (diffInDays < 30) {
    const weeks = Math.floor(diffInDays / 7);
    return weeks === 1 ? '1 semana atrás' : "".concat(weeks, " semanas atr\xE1s");
  } else if (diffInDays < 365) {
    const months = Math.floor(diffInDays / 30);
    return months === 1 ? '1 mês atrás' : "".concat(months, " meses atr\xE1s");
  } else {
    const years = Math.floor(diffInDays / 365);
    return years === 1 ? '1 ano atrás' : "".concat(years, " anos atr\xE1s");
  }
}