function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useRef, useState } from 'react';
import DeviceDetector from 'device-detector-js';
import AuthUtils from 'utils/AuthUtils';
import LoadingManager from 'components/Loading/LoadingManager';
import ValidationUtils from 'utils/ValidationUtils';
import useRulesValidation from 'hooks/useRulesValidation';
const useLogin = _ref => {
  let {
    onSuccess,
    onError
  } = _ref;
  const recaptchaRef = useRef(null);
  const [state, setState] = useState({
    email: '',
    password: '',
    showPassword: false,
    mfa: null
  });
  const {
    errors,
    validate,
    clearError
  } = useRulesValidation([{
    field: 'email',
    messageError: 'E-mail é obrigatório.',
    hasErrors: () => !state.email
  }, {
    field: 'email',
    messageError: 'E-mail inválido.',
    hasErrors: () => !ValidationUtils.isValidEmail(state.email)
  }, {
    field: 'password',
    messageError: 'Senha é obrigatória.',
    hasErrors: () => !state.password
  }, {
    field: 'password',
    messageError: 'Senha deve possuir no mínimo 8 caracteres.',
    hasErrors: () => !ValidationUtils.isLengthGte(state.password, 8)
  }]);
  const handleState = (key, value) => {
    setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      [key]: value
    }));
  };
  const togglePasswordView = () => {
    handleState('showPassword', !state.showPassword);
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (validate() && recaptchaRef) {
      recaptchaRef.current.execute();
    }
  };
  const doSubmit = recaptchaToken => {
    const {
      email,
      password,
      mfa
    } = state;
    const detector = new DeviceDetector();
    const device = detector.parse(navigator.userAgent);
    const deviceInfo = _objectSpread(_objectSpread({}, device), {}, {
      device: _objectSpread(_objectSpread({}, device.device), {}, {
        resolution: "".concat(window.screen.width, "x").concat(window.screen.height)
      })
    });
    LoadingManager.show();
    AuthUtils.login({
      email,
      password,
      redirectUrl: window.location.href,
      recaptchaToken,
      deviceInfo
    }).then(authenticateResponse => {
      if (authenticateResponse.data.mfa) {
        handleState('mfa', authenticateResponse.data.mfa);
        return;
      }
      onSuccess(authenticateResponse);
    }).catch(error => {
      if (onError) onError(error);
    }).finally(() => LoadingManager.close());
  };
  return {
    state,
    errors,
    recaptchaRef,
    validate,
    doSubmit,
    handleSubmit,
    handleState,
    togglePasswordView,
    clearError
  };
};
export default useLogin;