import { styled } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
export const StyledMenuTabs = styled(Tabs)({
  minHeight: '32px',
  '& .MuiTab-root': {
    minWidth: '60px',
    minHeight: '25px',
    paddingTop: 0,
    paddingBottom: 0,
    '& span': {
      textTransform: 'capitalize',
      color: '#808080'
    },
    '&.Mui-selected span': {
      color: '#8a00C1'
    }
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#8A00C1',
    height: '3.5px'
  }
});