function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useState, useEffect, useCallback } from 'react';
import StringUtils from 'utils/StringUtils';
function useDocumentFormat(value, defaultValue, documentFormatProps) {
  const options = _objectSpread({
    onChangeCallBack: () => {},
    onBlurCallBack: () => {},
    type: '',
    padStartZeroOnBlur: true
  }, documentFormatProps);
  const [document, setDocument] = useState('');
  const removeMask = document => document.replace(/\D/g, '');
  const formatDocument = useCallback(function (string) {
    let padStart = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const {
      type
    } = options;
    const clearString = string ? removeMask(string) : '';
    switch (type.toUpperCase()) {
      case 'CPF':
        return StringUtils.formatDocument(clearString.padStart(11, padStart && clearString.length >= 3 ? '0' : ''), 'CPF');
      case 'CNPJ':
        return StringUtils.formatDocument(clearString.padStart(14, padStart && clearString.length >= 3 ? '0' : ''), 'CNPJ');
      default:
        return StringUtils.formatDocument(string);
    }
  }, [options]);
  useEffect(() => {
    if (value !== undefined) setDocument(formatDocument(value));
  }, [value]);
  useEffect(() => {
    if (defaultValue !== undefined) setDocument(formatDocument(defaultValue));
  }, [defaultValue]);
  const callbackChange = useCallback(element => {
    const {
      value
    } = element;
    const {
      onChangeCallBack
    } = options;
    const maskedValue = formatDocument(value);
    setDocument(maskedValue);
    onChangeCallBack(element, maskedValue, removeMask(maskedValue));
  }, [options]);
  const callbackBlur = useCallback(element => {
    const {
      value
    } = element;
    const {
      onBlurCallBack,
      padStartZeroOnBlur
    } = options;
    const maskedValue = formatDocument(value, padStartZeroOnBlur);
    setDocument(maskedValue);
    onBlurCallBack(element, maskedValue, removeMask(maskedValue));
  }, [options]);
  const onChange = useCallback(e => {
    e.preventDefault();
    callbackChange(e.currentTarget);
  }, [callbackChange, formatDocument]);
  const onBlur = useCallback(e => {
    e.preventDefault();
    callbackBlur(e.currentTarget);
  }, [callbackBlur]);
  return [document, removeMask(document), onChange, onBlur];
}
export default useDocumentFormat;