import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { GLOBAL_NOTIFICATIONS, COMPONENT_TYPES } from 'constants/notificationHub';
export const isVirtualSellerNotification = (productIds, productId, componentType) => {
  return productIds.includes(productId) && componentType.includes(COMPONENT_TYPES.VIRTUAL_SELLER_NOTIFICATION);
};
export const filterNotifications = (notification, productId) => {
  const allowedRoutes = _get(notification, 'allowedRoutes', []);
  const blockedRoutes = _get(notification, 'blockedRoutes', []);
  const {
    productIds,
    productId: oldProductId,
    componentType
  } = notification;
  if (productIds) {
    return isVirtualSellerNotification(productIds, productId, componentType) || productIds.includes(GLOBAL_NOTIFICATIONS) && componentType.includes(COMPONENT_TYPES.REDIRECT_NOTIFICATION) || productIds.includes(productId) && componentType.includes(COMPONENT_TYPES.REDIRECT_NOTIFICATION) && (_isEmpty(allowedRoutes) || allowedRoutes.includes(window.location.href)) && (_isEmpty(blockedRoutes) || blockedRoutes.includes(window.location.href));
  }
  return notification.productId === oldProductId || notification.productId === GLOBAL_NOTIFICATIONS;
};
export const sortNotificationsByPriority = (a, b) => a.priority - b.priority;