function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useState, useEffect } from 'react';
import ProfileService from 'services/ProfileService';
import { FunctionUtils, ErrorUtils } from 'utils';
import Notifier from 'components/Notification/Notifier';
import _ from 'lodash';
import { ID_PRODUCTS, INITIAL_PROFILE } from './constants';
const initialState = {
  profileName: '',
  profileDescription: '',
  selectedProfile: INITIAL_PROFILE,
  selectedProducts: ID_PRODUCTS,
  products: []
};
const useCreateProfileForm = customerId => {
  const [profileForm, setProfileForm] = useState(initialState);
  const [baseProfiles, setBaseProfiles] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const handleProfileState = (name, value) => {
    const changedState = FunctionUtils.updateProperty(profileForm, name, value);
    setProfileForm(changedState);
  };
  const loadProducts = () => {
    ProfileService.getProducts(customerId).then(response => {
      const products = response.data;
      const changedProducts = products.map(product => {
        const {
          id,
          name
        } = product;
        return {
          id,
          name,
          functionalities: product.functionality.map(permission => {
            return _objectSpread(_objectSpread({}, permission), {}, {
              active: true
            });
          })
        };
      });
      handleProfileState('products', changedProducts);
    }).catch(error => {
      Notifier.error(ErrorUtils.normalizeErrorMessage(error));
    });
  };
  useEffect(() => {
    loadProducts();
  }, []);
  const loadProfiles = () => {
    Promise.all(ProfileService.getBaseProfiles(customerId)).then(values => {
      const baseProfiles = [values[0].data, values[1].data, values[2].data, values[3].data];
      setBaseProfiles(_.sortBy(baseProfiles, ['id']));
    }).catch(error => {
      Notifier.error(ErrorUtils.normalizeErrorMessage(error));
    });
  };
  const handleCreateNewProfile = () => {
    const selectedProducts = profileForm.products.filter(product => profileForm.selectedProducts.includes(product.id));
    const formattedProducts = selectedProducts.map(product => {
      return {
        id: product.id,
        functionalities: product.functionalities.map(permission => {
          const {
            id,
            active
          } = permission;
          return {
            id,
            active
          };
        })
      };
    });
    const personalizedProfile = {
      name: profileForm.profileName,
      description: profileForm.profileDescription,
      baseProfileId: profileForm.selectedProfile.id,
      products: formattedProducts
    };
    return ProfileService.createNewPersonalizedProfile(personalizedProfile, customerId);
  };
  return {
    loadProfiles,
    baseProfiles,
    activeStep,
    setActiveStep,
    profileForm,
    handleProfileState,
    handleCreateNewProfile,
    loadProducts
  };
};
export default useCreateProfileForm;