function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
const useStyles = makeStyles(() => ({
  table: {
    minWidth: props => props.minWidth
  }
}));
const useDynamicColumnsTable = _ref => {
  let {
    minWidth,
    columns,
    rows,
    enableScroll
  } = _ref;
  const classes = useStyles({
    minWidth
  });
  const [state, setState] = useState({
    checkableColumns: [],
    itemsExpand: []
  });
  const handleState = (key, value) => {
    setState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      [key]: value
    }));
  };
  const filterValuesBySelector = (array, field) => array.map(value => value[field]).filter(value => value);
  const filterItemsBySelector = (array, field) => array.map(value => value[field] && value).filter(value => value);
  const columnAccessors = filterValuesBySelector(columns, 'accessor');
  const columnAccessorsExpand = filterValuesBySelector(columns, 'expandAccessor');
  const listColumnsAccessors = filterItemsBySelector(columns, 'accessor');
  const listColumnsAccessorsExpand = filterItemsBySelector(columns, 'expandAccessor');
  const getActiveProperty = col => {
    return Object.prototype.hasOwnProperty.call(col, 'active') ? col.active : true;
  };
  useEffect(() => {
    handleState('checkableColumns', listColumnsAccessors.map(col => _objectSpread(_objectSpread({}, col), {}, {
      name: col.label,
      key: col.accessor,
      checked: getActiveProperty(col),
      active: getActiveProperty(col)
    })));
  }, [columns]);
  useEffect(() => {
    const existExpandAcessor = row => {
      const obj = listColumnsAccessorsExpand.find(item => !_isEmpty(row[item.expandAccessor]));
      return obj && obj.expanded;
    };
    const setListExpand = rows.map(row => Boolean(existExpandAcessor(row)));
    handleState('itemsExpand', setListExpand);
  }, [rows]);
  const updateExpanded = (value, index) => {
    const {
      itemsExpand
    } = state;
    const itemsExpandCopy = [...itemsExpand];
    itemsExpandCopy[index] = value;
    handleState('itemsExpand', itemsExpandCopy);
  };
  const normalizeColumns = array => {
    return array.map(item => _objectSpread(_objectSpread({}, item), {}, {
      active: getActiveProperty(item)
    }));
  };
  const normalizeSelectedColumns = array => array.map(col => _objectSpread(_objectSpread({}, col), {}, {
    active: col.checked
  }));
  const isActiveColumn = accessor => {
    const {
      checkableColumns
    } = state;
    return checkableColumns.some(col => col.checked && col.key === accessor);
  };
  const generateNewRows = (array, sorting) => {
    return array.map(value => {
      const row = {};
      sorting.map(key => {
        if (value[key]) {
          row[key] = value[key];
          return row;
        }
        return row;
      });
      return row;
    });
  };
  const normalizedColumns = normalizeColumns(listColumnsAccessors);
  const TableContainerScroll = enableScroll && {
    style: {
      height: "350px",
      overflow: 'auto'
    }
  };
  return {
    state,
    classes,
    handleState,
    columnAccessors,
    columnAccessorsExpand,
    normalizeSelectedColumns,
    listColumnsAccessorsExpand,
    isActiveColumn,
    generateNewRows,
    normalizedColumns,
    TableContainerScroll,
    updateExpanded
  };
};
export default useDynamicColumnsTable;