var _ProductUtils;
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Envs } from 'constants/envs';
import { ASSINATURAS_ID, AUTENTICA_ID, BASECERTA_ID, CREDITOMIX_ID, CREDITO_ID, GEOTIMIZE_ID, GESTAO_DA_CONTA_ID, LOCALIZE_ID, RECUPERE_ID, SMS_ID, VEICULOS_ID, WEBPHONE_ID, DOSSIE_ID } from 'constants/productIds';
import PRODUCTS from 'constants/products';
class ProductUtils {
  static isBackofficeClienteEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.BACKOFFICE_CLIENTE;
  }
  static isBackofficeInternoEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.BACKOFFICE_INTERNO;
  }
  static isSMSEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.SMS;
  }
  static isBaseCertaEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.BASE_CERTA;
  }
  static isLocalizeEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.ASSERTIVA_LOCALIZE;
  }
  static isGeotimizeEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.GEOTIMIZE;
  }
  static isAutenticaEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.AUTENTICA;
  }
  static isAssinaturasEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.ASSINATURAS;
  }
  static isRecupereEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.RECUPERE;
  }
  static isDossieEnv() {
    return ProductUtils.getProductNameByEnv() === PRODUCTS.DOSSIE;
  }
}
_ProductUtils = ProductUtils;
_defineProperty(ProductUtils, "getProductNameByEnv", () => {
  return Envs().PRODUCT_NAME || '';
});
_defineProperty(ProductUtils, "getProductIdByProductNameEnv", () => {
  if (!_ProductUtils.isValidIdNameProduct()) return 0;
  const productsIds = {
    [PRODUCTS.PAINEL]: GESTAO_DA_CONTA_ID,
    [PRODUCTS.SMS]: SMS_ID,
    [PRODUCTS.AUTENTICA]: AUTENTICA_ID,
    [PRODUCTS.ASSINATURAS]: ASSINATURAS_ID,
    [PRODUCTS.ASSERTIVA_LOCALIZE]: LOCALIZE_ID,
    [PRODUCTS.BACKOFFICE_CLIENTE]: GESTAO_DA_CONTA_ID,
    [PRODUCTS.BASE_CERTA]: BASECERTA_ID,
    [PRODUCTS.GEOTIMIZE]: GEOTIMIZE_ID,
    [PRODUCTS.CREDITO]: CREDITO_ID,
    [PRODUCTS.DOSSIE]: DOSSIE_ID,
    [PRODUCTS.CREDITOMIX]: CREDITOMIX_ID,
    [PRODUCTS.VEICULOS]: VEICULOS_ID,
    [PRODUCTS.WEBPHONE]: WEBPHONE_ID,
    [PRODUCTS.RECUPERE]: RECUPERE_ID
  };
  return productsIds[_ProductUtils.getProductNameByEnv()];
});
_defineProperty(ProductUtils, "getProductNameByProductId", productId => {
  if (typeof productId === 'undefined') return undefined;
  const productsNames = {
    [GESTAO_DA_CONTA_ID]: PRODUCTS.PAINEL,
    [SMS_ID]: PRODUCTS.SMS,
    [AUTENTICA_ID]: PRODUCTS.AUTENTICA,
    [ASSINATURAS_ID]: PRODUCTS.ASSINATURAS,
    [LOCALIZE_ID]: PRODUCTS.ASSERTIVA_LOCALIZE,
    [BASECERTA_ID]: PRODUCTS.BASE_CERTA,
    [GEOTIMIZE_ID]: PRODUCTS.GEOTIMIZE,
    [CREDITO_ID]: PRODUCTS.CREDITO,
    [DOSSIE_ID]: PRODUCTS.DOSSIE,
    [CREDITOMIX_ID]: PRODUCTS.CREDITOMIX,
    [VEICULOS_ID]: PRODUCTS.VEICULOS,
    [WEBPHONE_ID]: PRODUCTS.WEBPHONE,
    [RECUPERE_ID]: PRODUCTS.RECUPERE
  };
  return productsNames[productId];
});
/**
 *
 * @description para não precisar usar o originProduct no context do provider, passamos a utilizar a variável de ambiente REACT_APP_ASSERTIVA_PRODUCT_NAME, assim
 * O assertiva-ui consegui diferenciar qual aplicação está utilizando o assertiva-ui de forma mais fácil, o que é útil para alguns services que precisam distinguir
 * as aplicação na hora de chamar ou exibir algo.
 */
_defineProperty(ProductUtils, "isValidIdNameProduct", () => {
  const productValues = Object.values(PRODUCTS);
  if (!productValues.some(v => _ProductUtils.getProductNameByEnv() === v)) {
    const productNames = productValues.map(p => " ".concat(p));
    console.error("[ProductUtils.isValidIdNameProduct] environment REACT_APP_ASSERTIVA_PRODUCT_NAME not found. Please set one of ".concat(productNames, " in .env file with name REACT_APP_ASSERTIVA_PRODUCT_NAME."));
    return false;
  }
  return true;
});
_defineProperty(ProductUtils, "hasProductIdName", productIdName => {
  if (!_ProductUtils.isValidIdNameProduct()) return false;
  return productIdName === _ProductUtils.getProductNameByEnv();
});
ProductUtils.isValidIdNameProduct();
export default ProductUtils;