export const ChipColorNotification = {
  AUTENTICA: '#A80CE5',
  ASSINATURAS: '#A80CE5',
  'BASE CERTA': '#A80CE5',
  'CRÉDITO MIX': '#A80CE5',
  GEOTIMIZE: '#A80CE5',
  LOCALIZE: '#A80CE5',
  RECUPERE: '#A80CE5',
  SMS: '#A80CE5',
  VEÍCULOS: '#A80CE5',
  WEBPHONE: '#A80CE5',
  MARKETING: '#C5005A',
  FINANCEIRO: '#004FC5',
  'CARTÃO DE CRÉDITO': '#25283C',
  ATUALIZAÇÕES: '#5B781E',
  SEGURANÇA: '#E18838'
};